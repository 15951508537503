import actionType from "../Actions/actionType";
import CustomConsole from "../../../CustomConsole";

const initialState = {
  error: true,
  msg: "",
  updateMsgUser: "",
  updateRoleMsg: "",
  userProfileData: {
    consumer: {},
    user: {},
  },
  actionTriger: true,
  userProfileMsg: "",
  supplierDriverMsg: "",
  supplierDriverData: [],
  userStockData: [],
  userDepositData: [],
  updateMsgGst: "",
  enableUserMsg: "",
  disableUserMsg: "",
  editUserAddressMsg: "",
  deleteUserAddressMsg: "",
  addUserAddressMsg: "",
  addStockMsg: "",
  returnCansOrderMsg: "",
  reduceCansMsg: "",
  updateMsgSubscription: "",
  rollBackcansMsg: "",
  putRollBackCansMsg: "",
  wfsDiasbleuserSitchMsg: "",
  users: [],
  address: [],
  addresses: [],
  accounts: [],
  updateSupplierDetailsMsg: "",
  assignedWfsAddress: [],
  assignedWfsStock: [],
  subscriptionDetails: [],
  subscriptionDetailsError: true,
  is_revert_back_enabled: false,
  deliverySlotEnableDisable: {},
  putRollBackCans: {},
  deleteUserMsg: "",
  wfsDisableuserSwitch: {},
  walletDeposittransaction:{},
  walletAmount: 0,
  addWalleterror: false,
  addWalletmsg: "",
  reduceWalleterror: false,
  reduceWalletmsg: "",
  influencerError: false,

  influencerMsg: "",
  influencerReqError: false,
  influencerReqMsg: "",
  referralCode: "",
  referralCodeError: false,
  referralCodeMsg: "",
  updateReferralCodeError: false,
  updateReferralCodeMsg: "",
  postAddWaterDrops: {},
  billingAddress: [],
  userProfile: {},
  userNames: [],
  getSupplierDriverDataAdmin: [],
  distStock: {},
  editAddressFlag: true,
};

const userDetailsAdminReducer = (state = initialState, action) => {
  // CustomConsole(`action type: ${action.type}`);
  switch (action.type) {
    case actionType.UPDATE_USER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        updateMsgUser: action.payload.msg,
      };
    case actionType.UPDATE_USER_ROLEID:
      return {
        ...state,
        error: action.payload.error,
        updateRoleMsg: action.payload.msg,
      };
    case actionType.GET_PROFILE_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        userProfileMsg: action.payload.msg,
        userProfileData: action.payload.profile,
      };
    case actionType.GET_SUPPLIER_DRIVER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        supplierDriverMsg: action.payload.msg,
        supplierDriverData: action.payload.data,
      };
    case actionType.GET_USERSTOCK_DATA:
      return {
        ...state,
        error: action.payload.error,
        userStockData: action.payload.data,
        distStock: action.payload.distStock,
      };
    case actionType.GET_DEPOSIT_DATA:
      return {
        ...state,
        error: action.payload.error,
        userDepositData: action.payload.data,
      };
    case actionType.UPDATE_GST_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        updateMsgGst: action.payload.msg,
      };
    case actionType.GET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionDetailsError: action.payload.error,
        updateMsgSubscription: action.payload.msg,
        subscriptionDetails: action.payload.data,
      };
    case actionType.GET_USER_ADDRESS_ADMIN: {
      CustomConsole(action.payload);
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        address: action.payload.addresses,
        addresses: action.payload.addresses,
      };
    }
    case actionType.GET_ACCOUNTS_DETAILS_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        accounts: action.payload.accounts,
      };
    case actionType.ENABLE_USER_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        enableUserMsg: action.payload.msg,
      };
    case actionType.DISABLE_USER_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        disableUserMsg: action.payload.msg,
      };
    case actionType.DELETE_USER:
      return {
        ...state,
        error: action.payload.error,
        deleteUserMsg: action.payload.msg,
      };
    case actionType.EDIT_USER_ADDRESS_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        editAddressFlag: action.payload.error,
        editUserAddressMsg: action.payload.msg,
        actionTriger: action.payload.error,
      };
    case actionType.DELETE_USER_ADDRESS_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        deleteUserAddressMsg: action.payload.msg,
        editAddressFlag: action.payload.error,
      };
    case actionType.ADD_USER_ADDRESS_ADMIN:
      return {
        ...state,
        error: action.payload.error,
        editAddressFlag: action.payload.error,

        addUserAddressMsg: action.payload.msg,
      };
    case actionType.UPDATE_USER_STOCK:
      return {
        ...state,
        error: action.payload.error,
        addStockMsg: action.payload.msg,
      };
    case actionType.UPDATE_CRATE_USER_STOCK:
      return {
        ...state,
        error: action.payload.error,
        addStockMsg: action.payload.msg,
      };
    case actionType.RETURN_CANS_ORDER:
      return {
        ...state,
        error: action.payload.error,
        returnCansOrderMsg: action.payload.msg,
      };
    case actionType.REDUCE_USER_STOCK:
      return {
        ...state,
        error: action.payload.error,
        reduceCansMsg: action.payload.msg,
      };
    case actionType.UPDATE_SUPPLIER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        updateSupplierDetailsMsg: action.payload.msg,
      };
    case actionType.GET_ASSIGNED_WFS:
      return {
        ...state,
        error: action.payload.error,
        assignedWfsAddress: action.payload.data,
        assignedWfsStock: action.payload.stock,
      };
      case actionType.DEPOSIT_USER_STOCK:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        walletDeposittransaction: action.payload.data,
      };

    case actionType.GET_USER_CANS_ROLLBACK:
      return {
        ...state,
        error: action.payload.error,
        rollBackcansMsg: action.payload.msg,
        is_revert_back_enabled: action.payload.data.is_revert_back_enabled,
      };
    case actionType.PUT_CANS_ROLLBACK:
      return {
        ...state,
        error: action.payload.error,
        putRollBackCansMsg: action.payload.msg,
        putRollBackCans: action.payload.data,
        is_revert_back_enabled: action.payload.is_enabled,
      };
    case actionType.DISABLE_WFS_SWITCH:
      return {
        ...state,
        error: action.payload.error,
        wfsDiasbleuserSitchMsg: action.payload.msg,
        wfsDisableuserSwitch: action.payload.data,
      };
    case actionType.REQUEST_REPLACEMENT_CAN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_WALLET_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        walletAmount: action.payload.data.amount,
      };
    case actionType.ADD_WALLET_MONEY:
      return {
        ...state,
        addWalleterror: action.payload.error,
        addWalletmsg: action.payload.msg,
      };
    case actionType.REDUCE_WATER_WALLET_MONEY:
      return {
        ...state,
        reduceWalleterror: action.payload.error,
        reduceWalletmsg: action.payload.msg,
      };
    case actionType.INFULENCER_ENABLE_DISABLE:
      return {
        ...state,
        influencerError: action.payload.error,
        influencerMsg: action.payload.msg,
      };
    case actionType.MAKE_USER_INFLUENCER:
      return {
        ...state,
        influencerReqError: action.payload.error,
        influencerReqMsg: action.payload.msg,
      };
    case actionType.GET_REFERRAL_CODES:
      return {
        ...state,
        referralCode: action.payload.ref_id,
        referralCodeError: action.payload.error,
        referralCodeMsg: action.payload.msg,
      };
    case actionType.UPDATE_REFERRAL_CODE:
      return {
        ...state,
        updateReferralCodeError: action.payload.error,
        updateReferralCodeMsg: action.payload.msg,
      };
    case actionType.ADD_WATER_DROPS:
      return {
        ...state,
        error: action.payload.error,
        postAddWaterDrops: action.payload.data,
        msg: action.payload.msg,
      };
    case actionType.GET_BILLING_ADDRESS:
      return {
        ...state,
        error: action.payload.error,
        billingAddress: action.payload.data,
        userProfile: action.payload.user_profile,
        msg: action.payload.msg,
      };
    case actionType.GET_USERS_NAME:
      return {
        ...state,
        error: action.payload.error,
        userNames: action.payload.userDetails,
        msg: action.payload.msg,
      };
    case actionType.TRANSFER_CANS_DISTRIBUTOR_CREATE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        tansferDistributorCanCreateOrder: action.payload.data,
        msg: action.payload.msg,
      };
      case actionType.REFUND_CONSUMER_STOCK:
        return {
          ...state,
          error: action.payload.error,
          msg: action.payload.msg,
        };
      case actionType.CLEAR_UPDATE_USER_DATA:
        return {
          ...initialState,
          // validatedData: action.payload.data,
        };
    default:
      return state;
  }
};

export default userDetailsAdminReducer;
