import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomConsole from "../../CustomConsole";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";

import DownloadIcon from "@mui/icons-material/Download";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { ReportbasedonType } from "../Redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function WalletTypeTable({
  allReportData,
  date,
  reportType,
  setDate,
  setTypeReportPgNo,
  reportTypePgNo,
  pageNumber,
  setPageNumber,
}) {
  const dispatch = useDispatch();
  const [reportFilterDialog, setReportFilterDialog] = React.useState(false);
  const [enableForBtn, setEnableForBtn] = React.useState(false);
  const [enableBakBtn, setEnableBakBtn] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(25);
  const [testArr, setTestArr] = React.useState([]);
  // const handelSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(ReportbasedonType(reportType, date));
  //   setReportFilterDialog(false);
  // };
  // const handleClose = (e) => {
  //   setReportFilterDialog(false);
  // };
  const csvLink = {
    filename: "Report",
    data: allReportData.reportBasedonType,
  };
  CustomConsole(reportType);
  // React.useEffect(() => {
  //   dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1,pageSize));
  // }, [reportTypePgNo]);
  const pageChangeForward = () => {
    CustomConsole(pageNumber);
    if (allReportData.reportBasedonType.length > 0) {
      setPageNumber(pageNumber + 1);
      CustomConsole(pageNumber);
      setTypeReportPgNo(pageNumber + 1);
    }

    if (pageNumber - 1 >= 1) {
      setEnableBakBtn(false);
    }
  };

  const pageChangeBackward = () => {
    CustomConsole(pageNumber);
    if (pageNumber - 1 >= 1) {
      setPageNumber(pageNumber - 1);
      setTypeReportPgNo(pageNumber - 1);
    }
    if (pageNumber - 1 <= 1) {
      setEnableBakBtn(true);
    }
  };
  const handleSearchPageSize = () => {
    dispatch(ReportbasedonType(reportType, date, reportTypePgNo - 1, pageSize));
  };

  const handleChangePageSize = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setPageSize(() => event.target.value);
  };

  return (
    <Box sx={{ maxWidth: { xs: 350, lg: 1, md: 1, sm: 1 } }}>
      {reportType == 100 ? (
        <>
          <Box sx={{ margin: "1em" }}>
            <span style={{ fontSize: "1em" }}>
              <strong>Wallet Transaction Report</strong>
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ maxWidth: "120px", maxHeight: "10px", mt: 2 }}>
              <TextField
                size="small"
                value={pageSize}
                label="Page size"
                // onChange={(e) => {
                //   setPageSize(e.target.value);
                //   // setSearchOrderFlag(true);
                // }}

                InputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handleChangePageSize, // Attach the input event handler
                  endAdornment: (
                    <Material.InputAdornment position="end">
                      <MuiIcons.Search
                        cursor="pointer"
                        size="30px"
                        onClick={handleSearchPageSize}
                      />
                    </Material.InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // gap: "15px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeBackward}
                  // disabled={enableBakBtn}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Box>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  // marginLeft: "10px",
                  // marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Typography>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeForward}
                  disabled={enableForBtn}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <CSVLink {...csvLink}>Download</CSVLink>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Transaction Id</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Payment Method</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReportData.reportBasedonType.map((row) => (
                  <StyledTableRow key={row.order_id}>
                    <StyledTableCell component="th" scope="row">
                      {row.transaction_id}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.transaction_ts}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.amount}
                    </StyledTableCell>
                    <StyledTableCell>{row.payment_method}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </>
      ) : (
        " "
      )}

      {reportType == 200 ? (
        <>
          <Box sx={{ margin: "1em" }}>
            <span style={{ fontSize: "1em" }}>
              <strong>Current Wallet Details Report</strong>
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ maxWidth: "120px", maxHeight: "10px", m: 1 }}>
              <TextField
                size="small"
                value={pageSize}
                label="Page size"
                // onChange={(e) => {
                //   setPageSize(e.target.value);
                //   // setSearchOrderFlag(true);
                // }}

                InputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handleChangePageSize, // Attach the input event handler
                  endAdornment: (
                    <Material.InputAdornment position="end">
                      <MuiIcons.Search
                        cursor="pointer"
                        size="30px"
                        onClick={handleSearchPageSize}
                      />
                    </Material.InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeBackward}
                  // disabled={enableBakBtn}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Box>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  // marginLeft: "10px",
                  // marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Typography>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeForward}
                  disabled={enableForBtn}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
            </Box>
            <Box>
              <CSVLink {...csvLink}>Download</CSVLink>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="left">Phone</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Current Balance</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReportData.reportBasedonType.map((row) => (
                  <StyledTableRow key={row.order_id}>
                    <StyledTableCell component="th" scope="row">
                      {row.fullname}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.phone}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell>{row.current_amount}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        " "
      )}
      {reportType == 900 ? (
        <>
          <Box sx={{ margin: "1em" }}>
            <span style={{ fontSize: "1em" }}>
              <strong>Yesterday Transaction Report</strong>
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ maxWidth: "120px", maxHeight: "10px", m: 1 }}>
              <Material.TextField
                size="small"
                value={pageSize}
                label="Page size"
                // onChange={(e) => {
                //   setPageSize(e.target.value);
                //   // setSearchOrderFlag(true);
                // }}

                InputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handleChangePageSize, // Attach the input event handler
                  endAdornment: (
                    <Material.InputAdornment position="end">
                      <MuiIcons.Search
                        cursor="pointer"
                        size="30px"
                        onClick={handleSearchPageSize}
                      />
                    </Material.InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // gap: "15px",
                padding: "5px",
                marginTop: "10px",
              }}
            >
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeBackward}
                  // disabled={enableBakBtn}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Box>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{
                  // marginLeft: "10px",
                  // marginRight: "10px",
                  backgroundColor: "#2D81FF",
                  color: "white",
                  padding: "5px",
                  width: "40px",
                  height: "35px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {pageNumber}
              </Typography>
              <Box>
                <Button
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={pageChangeForward}
                  disabled={true}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <CSVLink {...csvLink}>Download</CSVLink>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Wallet Balance</StyledTableCell>
                  <StyledTableCell align="left">
                    Pay Sharp to Wallet
                  </StyledTableCell>
                  <StyledTableCell>Razor pay to Wallet</StyledTableCell>
                  <StyledTableCell>Total Razor pay Amount</StyledTableCell>
                  <StyledTableCell>Total Pay Sharp Amount</StyledTableCell>
                  <StyledTableCell>Wallet to order</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReportData.reportBasedonType.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {row.wallet_balance === null
                        ? "0.00"
                        : row.wallet_balance}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.paysharp_to_wallet === "0"
                        ? "0.00"
                        : row.paysharp_to_wallet}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.razorpay_to_wallet === "0"
                        ? "0.00"
                        : row.razorpay_to_wallet}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.total_razorpay_amount === "0"
                        ? "0.00"
                        : row.total_razorpay_amount}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.total_paysharp_amount === "0"
                        ? "0.00"
                        : row.total_paysharp_amount}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.wallet_to_order === "0"
                        ? "0.00"
                        : row.wallet_to_order}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </>
      ) : (
        " "
      )}
    </Box>
  );
}
