import React from "react";
import * as Material from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

function DeleteUserDialog(props) {
  const handleDelete = () => {
    props.handleDelete();
  };
  const handleClose = () => {
    props.handleClose();
  };
  return (
    <Material.Box>
      <Dialog open={props.deleteUserDialog}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Material.Box sx={{ fontSize: "40px" }}>
            <Material.Typography>
              Are you Confirm To Delete User
            </Material.Typography>
          </Material.Box>
        </DialogContent>
        <DialogActions>
          <Material.Button variant="outlined" onClick={handleClose}>
            No
          </Material.Button>
          <Material.Button
            variant="outlined"
            color="error"
            onClick={handleDelete}
          >
            Delete
          </Material.Button>
        </DialogActions>
      </Dialog>
    </Material.Box>
  );
}

export default DeleteUserDialog;
