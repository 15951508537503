import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import { GetBillingAddress } from "../Redux/Actions/userDetailsAdminAction";
import { GetUserBasedName } from "../Redux/Actions/userDetailsAdminAction";
import CloseIcon from "@mui/icons-material/Close";
import CustomConsole from "../../CustomConsole";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  PostPoRequest,
  GetPoTrackDetails,
} from "../Redux/Actions/adminPoTrackPostpaid";
import { toast } from "react-toastify";
import { CardContent } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreatePoDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const fileUploadDetails = useSelector((state) => state.fileHandler);
  const addUserAdminDetails = useSelector(
    (state) => state.addUserAdminDetails.users
  );
  const updateUserDetails = useSelector(
    (state) => state.userDetailsUpdate.billingAddress
  );
  const userProfile = useSelector(
    (state) => state.userDetailsUpdate.userProfile
  );
  // const userAddress = useSelector((state) => state.userDetailsUpdate.address);
  const poDetails = useSelector((state) => state.poTrackDetails);
  const userName = useSelector((state) => state.userDetailsUpdate.userNames);
  const productsAvailable = useSelector(
    (state) => state.newProductContainerDetails.getAvailableProducts
  );

  // const [userDetails, setUserDetails] = React.useState([]);

  const uploadPoRef = React.useRef(null);

  const [isPoUploaded, setPoUploaded] = React.useState(false);
  const [poFile, setPoFIle] = React.useState("");
  const [orderType, setOrderType] = React.useState(true);
  const [startDate, setStartDate] = React.useState(
    moment().tz("Asia/Kolkata").format()
  );
  const [endDate, setEndDate] = React.useState(
    moment().tz("Asia/Kolkata").format()
  );
  const [phone, setPhone] = React.useState("");
  const [poId, setPoId] = React.useState("");
  const [address, setAddress] = React.useState({});
  const [addressCalled, setAddressCalled] = React.useState(false);

  const [orderQty, setOrderQty] = React.useState("");
  const [poPlaced, setPoPlaced] = React.useState(false);
  const [nameSearch, setNameSearch] = React.useState([]);
  const [getName, setGetName] = React.useState("");
  const [getPhone, setGetPhone] = React.useState("");
  const [productId, setProductId] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [collectProducts, setCollectProducts] = React.useState([]);
  const [availableProd, setAvailableProd] = React.useState([]);

  const skuData = [
    {
      container_master_id: 189,
      container_capacity: "20_Liters",
      container_material: "COPPER",
      life_span: 0,
      amount: "1.00",
      is_active: true,
      updated_by: 284,
      created_at: "2022-07-09T05:59:21.000Z",
      sku_qty: 1,
      name: "",
      description: "",
      image: "",
      cgst_code: "",
      sgst_code: "",
      hsn_code: "",
      igst_code: "",
      cgst_percentage: "0.00",
      sgst_percentage: "0.00",
      igst_percentage: "0.00",
      min_book_qty: 1,
      max_book_qty: "1",
      delivery_charges: "0.00",
      order_type: 0,
    },
    {
      container_master_id: 857,
      container_capacity: "20_Liters",
      container_material: "PET",
      life_span: 30,
      amount: "200.00",
      is_active: true,
      updated_by: 1562,
      created_at: "2023-12-01T10:55:52.000Z",
      sku_qty: 1,
      name: "",
      description: "",
      image: "",
      cgst_code: "",
      sgst_code: "",
      hsn_code: "",
      igst_code: "",
      cgst_percentage: "0",
      sgst_percentage: "0",
      igst_percentage: "0",
      min_book_qty: 1,
      max_book_qty: "1",
      delivery_charges: "0",
      order_type: 0,
    },
    {
      container_master_id: 710,
      container_capacity: "20_Liters",
      container_material: "POLYCARBONATE",
      life_span: 25,
      amount: "25.00",
      is_active: true,
      updated_by: 1208,
      created_at: "2023-06-06T07:30:38.000Z",
      sku_qty: 1,
      name: "",
      description: "",
      image: "",
      cgst_code: "",
      sgst_code: "",
      hsn_code: "",
      igst_code: "",
      cgst_percentage: "0.00",
      sgst_percentage: "0.00",
      igst_percentage: "0.00",
      min_book_qty: 1,
      max_book_qty: "1",
      delivery_charges: "0.00",
      order_type: 0,
    },
    {
      container_master_id: 709,
      container_capacity: "20_Liters",
      container_material: "PVC",
      life_span: 25,
      amount: "30.00",
      is_active: true,
      updated_by: 1208,
      created_at: "2023-06-06T07:30:38.000Z",
      sku_qty: 1,
      name: "",
      description: "",
      image: "",
      cgst_code: "",
      sgst_code: "",
      hsn_code: "",
      igst_code: "",
      cgst_percentage: "0.00",
      sgst_percentage: "0.00",
      igst_percentage: "0.00",
      min_book_qty: 1,
      max_book_qty: "1",
      delivery_charges: "0.00",
      order_type: 0,
    },
    {
      container_master_id: 864,
      container_capacity: "750ml",
      container_material: "GLASS",
      life_span: 1000,
      amount: "70.00",
      is_active: true,
      updated_by: 1208,
      created_at: "2023-12-11T06:21:12.210Z",
      sku_qty: 12,
      name: "750ml Glass Bottle",
      description: "You can return the glass bottle and earn the Rewards",
      image: "",
      cgst_code: "74536",
      sgst_code: "745269",
      hsn_code: "125466",
      igst_code: "856423",
      cgst_percentage: "6",
      sgst_percentage: "6",
      igst_percentage: "2",
      min_book_qty: 1,
      max_book_qty: "1",
      delivery_charges: "10",
      order_type: 100,
    },
    {
      container_master_id: 865,
      container_capacity: "750mls",
      container_material: "GLASS",
      life_span: 1000,
      amount: "71.00",
      is_active: true,
      updated_by: 1208,
      created_at: "2023-12-11T06:22:45.022Z",
      sku_qty: 12,
      name: "750ml Glass Bottle",
      description: "You can return the glass bottle and earn the Rewards",
      image: "",
      cgst_code: "74536",
      sgst_code: "745269",
      hsn_code: "125466",
      igst_code: "856423",
      cgst_percentage: "6",
      sgst_percentage: "6",
      igst_percentage: "2",
      min_book_qty: 1,
      max_book_qty: "1",
      delivery_charges: "10",
      order_type: 100,
    },
  ];

  const triggerPoRef = () => {
    uploadPoRef.current.click();
  };

  const poUploadChange = (e) => {
    const selectedFile = e.target.files[0];
    dispatch(UploadFile(selectedFile));
    setPoUploaded(true);
  };

  const handlePhoneChange = (event) => {
    // Get the value entered in the text field
    const inputValue = event.target.value;

    // Remove any non-numeric characters using regular expression
    const numericValue = inputValue.replace(/\D/g, "");

    // Update the value in the text field
    event.target.value = numericValue;

    setPhone(() => event.target.value);
  };

  const handleQuantityChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    setOrderQty(() => event.target.value);
  };

  const onChangeStartDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setStartDate(() => moment(newValue.$d).tz("Asia/kolkata").format());

      CustomConsole(newValue.$d);
    }
  };

  const onChangeEndDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setEndDate(() => moment(newValue.$d).tz("Asia/kolkata").format());

      CustomConsole(newValue.$d);
    }
  };

  const onSubmitCreatePoForm = (e) => {
    e.preventDefault();

    const datevalidator = moment(startDate).isBefore(
      moment(endDate).endOf("day")
    );
    CustomConsole(datevalidator);

    if (collectProducts && collectProducts.length < 1) {
      toast.error("Select Products to create PO order!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (!datevalidator) {
      toast.error("From date should be before the to date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (
      phone.length === 10 &&
      startDate &&
      endDate &&
      parseInt(orderQty) > 0 &&
      ((orderType === true && poFile !== "" && poId !== "") ||
        (orderType === false && poFile === "")) &&
      Object.keys(address).length
    ) {
      let reqObj = {
        po_filename: poFile,
        is_po_order: orderType,
        pp_details: {
          billing_address: {
            ...address,
          },
        },
        start_date: startDate,
        end_date: endDate,
        userPhone: phone,
        po_number: poId,
        qty_details: collectProducts,
      };
      CustomConsole(reqObj);
      dispatch(PostPoRequest(reqObj));
      setOpen(false);
      setPoPlaced(true);
    } else {
      toast.error("Error in the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPoFIle("");
  };

  // {
  //   "qty": 10,
  //   "container_capacity": "20_Liters",
  //   "container_material": "PET"
  // }

  const handleMultipleProductAdd = (material, capacity, id) => {
    CustomConsole("-----inside---");
    CustomConsole(orderQty);
    if (orderQty !== "" && !isNaN(orderQty) && material && capacity) {
      const existingProductIndex = collectProducts.findIndex(
        (products) => products.id === id
      );
      CustomConsole("---Existing products---");
      CustomConsole(existingProductIndex);
      if (existingProductIndex !== -1) {
        const updatedProducts = collectProducts.filter(
          (obj, idx) => idx !== existingProductIndex
        );
        const updatedProductObj = {
          qty: parseFloat(orderQty),
          container_capacity: capacity,
          container_material: material,
          id: id,
        };
        return setCollectProducts([...updatedProducts, updatedProductObj]);
      } else {
        // Product does not exist, add a new entry
        const productObj = {
          qty: parseFloat(orderQty),
          container_capacity: capacity,
          container_material: material,
          id: id,
        };
        return setCollectProducts((prev) => [...prev, productObj]);
      }
    }
  };

  const removeProduct = (id) => {
    const existingProductIndex = collectProducts.findIndex(
      (products) => products.id === id
    );
    const updatedProducts = collectProducts.filter(
      (obj, idx) => idx !== existingProductIndex
    );
    return setCollectProducts([...updatedProducts]);
  };

  React.useEffect(() => {
    if (open) {
      dispatch(GetAvailableProducts());
    }
  }, [open]);

  React.useEffect(() => {
    if (productsAvailable) {
      let mappedProducts = productsAvailable.map((obj) => obj.product_details);
      setAvailableProd(() => (mappedProducts ? mappedProducts : []));
    }
  }, [productsAvailable]);

  React.useEffect(() => {
    CustomConsole("-----Products---");
    CustomConsole(collectProducts);
  }, [collectProducts]);

  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(userName);
    if (userName) {
      setNameSearch(userName);
    } else {
      setNameSearch([]);
    }
  }, [userName]);

  React.useEffect(() => {
    CustomConsole("------Inside the toasteffect---------");

    if (poPlaced && poDetails.error === false) {
      let reqObj = {
        page_size: "",
        page_number: "",
        pp_track_id: "",
        order_status: "",
        sort: "",
        phone: "",
      };
      dispatch(GetPoTrackDetails(reqObj));

      setPoFIle("");
      setStartDate(moment().tz("Asia/Kolkata").format());
      setEndDate(moment().tz("Asia/Kolkata").format());
      setOrderQty("");
      setPhone("");
      setPoId("");

      toast.success(poDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPoUploaded(false);
      setPoPlaced(false);
    }
  }, [poDetails]);

  React.useEffect(() => {
    CustomConsole(fileUploadDetails);
    if (fileUploadDetails.isFileUploaded === true && isPoUploaded) {
      setPoFIle(fileUploadDetails.fileName);
      setPoUploaded(false);
      fileUploadDetails.isFileUploaded = false;
    }
  }, [fileUploadDetails]);

  React.useEffect(() => {
    CustomConsole(addUserAdminDetails);
    CustomConsole("---------Setting Uid---------");
    if (addUserAdminDetails && addUserAdminDetails.length) {
      CustomConsole("---------Inside if Setting Uid---------");
    }
  }, [addUserAdminDetails]);

  React.useEffect(() => {
    if (updateUserDetails && updateUserDetails[0] && addressCalled) {
      setAddress(updateUserDetails[0]);
      setAddressCalled(false);
    } else {
      setAddress({});
    }
  }, [updateUserDetails]);

  React.useEffect(() => {
    if (getName && getName.length) {
      const timerId = setTimeout(() => {
        CustomConsole(getName);
        const reqObj = {
          name: getName,
          ignore_app_loading_spinner: true,
        };
        dispatch(GetUserBasedName(reqObj));
      }, 200);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [getName]);

  React.useEffect(() => {
    CustomConsole("-----Address----");
    CustomConsole(getPhone);
    if (getPhone) {
      setPhone(getPhone);
    }
  }, [getPhone]);

  React.useEffect(() => {
    const numericValue = phone.replace(/\D/g, "");
    if (numericValue.length === 10) {
      let reqObj = {
        phone: phone,
        userId: "",
      };
      dispatch(GetBillingAddress(reqObj));
      setAddressCalled(true);
    }
  }, [phone]);

  React.useEffect(() => {
    if (productId) {
      const selectedProduct = availableProd.filter((obj) => {
        return obj.id === productId;
      });
      CustomConsole(selectedProduct);
      setSelectedProduct(() => selectedProduct[0]);
    }
  }, [productId]);

  React.useEffect(() => {
    CustomConsole("------Available product details-----");
    CustomConsole(availableProd);
  }, [availableProd]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <form onSubmit={onSubmitCreatePoForm}>
          <DialogContent>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 1, sm: 2 }}
              useFlexGap
              flexWrap="wrap"
              mt={2}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  // minDate={moment().subtract(0, 'days')}
                  value={startDate}
                  onChange={onChangeStartDate}
                  disablePast
                  renderInput={(params) => (
                    <TextField size="small" {...params} required />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={onChangeEndDate}
                  // minDate={moment().subtract(0, 'days')}

                  disablePast
                  renderInput={(params) => (
                    <TextField size="small" {...params} required />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 1, sm: 2 }}
              useFlexGap
              flexWrap="wrap"
              justifyContent="space-between"
              mt={2}
            >
              <FormControl size="small" sx={{ width: "210px" }}>
                <InputLabel
                  id="product-type-select-label"
                  sx={{ fontSize: "1rem", marginTop: "2px" }}
                >
                  Products
                </InputLabel>
                <Select
                  labelId="product-type-select-label"
                  id="product-type-select"
                  value={productId}
                  label="Product"
                  onChange={(e) => setProductId(e.target.value)}
                >
                  {availableProd.map((obj, idx) => {
                    return (
                      <MenuItem
                        value={obj.id}
                        sx={{ fontSize: "0.76rem" }}
                        key={idx}
                      >
                        {`${obj.capacity},${obj.material}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label="Total Quantity"
                required
                sx={{ width: "45%" }}
                value={orderQty}
                inputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handleQuantityChange, // Attach the input event handler
                }}
                helperText={
                  orderQty && selectedProduct?.sku_qty?.[0]
                    ? `Total Pack of ${orderQty}X${
                        selectedProduct.sku_qty[0]
                          ? selectedProduct.sku_qty[0]
                          : 0
                      }=${
                        selectedProduct.sku_qty[0] && orderQty
                          ? selectedProduct.sku_qty[0] * orderQty
                          : 0
                      }`
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          handleMultipleProductAdd(
                            selectedProduct.material,
                            selectedProduct.capacity,
                            selectedProduct.id
                          )
                        }
                      >
                        Add
                      </Button>
                    </InputAdornment>
                  ),
                }}
                // helperText={`${selectedProduct.container_material},${selectedProduct.container_capacity} for 1 quantity will be having ${selectedProduct.sku_qty} products`}
              />
            </Stack>

            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 1, sm: 2 }}
              justifyContent="space-between"
              useFlexGap
              flexWrap="wrap"
              mt={2}
            >
              <FormControl size="small" sx={{ width: "210px" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontSize: "1rem", marginTop: "2px" }}
                >
                  Order Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderType}
                  label="Order Type"
                  onChange={(e) => setOrderType(e.target.value)}
                >
                  <MenuItem value={true} sx={{ fontSize: "0.76rem" }}>
                    PO Order
                  </MenuItem>
                  <MenuItem value={false} sx={{ fontSize: "0.76rem" }}>
                    PO less Order
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label="PO ID"
                value={poId}
                onChange={(e) => setPoId(e.target.value)}
              />
            </Stack>

            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 1, sm: 2 }}
              justifyContent="space-between"
              useFlexGap
              flexWrap="wrap"
              mt={2}
            >
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 210 }}
                options={nameSearch}
                autoHighlight
                getOptionLabel={(nameSearch) =>
                  `${nameSearch.email}${nameSearch.fullname}`
                }
                onChange={(_, newValue) => {
                  setGetPhone(newValue ? newValue.phone : ""); // Set the selected description as the value
                }}
                renderOption={(props, nameSearch) => (
                  <Box
                    component="li"
                    // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {nameSearch.fullname} {nameSearch.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Name"
                    value={setGetName}
                    onChange={(e) => {
                      setGetName(e.target.value);
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                label="Phone"
                value={phone}
                inputProps={{
                  maxLength: 10, // Optional: You can set the maximum length if needed
                  onInput: handlePhoneChange, // Attach the input event handler
                }}
                required
              />
            </Stack>

            <Grid container spacing={1} mt={0.5}>
              {collectProducts
                ? collectProducts.map((val, idx) => {
                    return (
                      <Grid item xs={4} sm={4} md={4} lg={4} key={idx}>
                        <Box
                          sx={{
                            padding: "5px",
                            backgroundColor: "#F3DCB5",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              fontFamily: "sans-serif",
                            }}
                          >
                            {`${
                              val.container_capacity.length > 5
                                ? val.container_material.slice(0, 4) + ".."
                                : val.container_material
                            },${val.container_capacity},`}
                            &nbsp;&nbsp;{" "}
                            <span style={{ color: "grey" }}>{val.qty}</span>
                            {/* {val.length >20? val.slice(0,20)+"....":val} */}
                          </Typography>
                          <CloseIcon
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              removeProduct(val.id);
                            }}
                          />
                        </Box>
                      </Grid>
                    );
                  })
                : null}
            </Grid>

            <Stack mt={2}>
              {Object.keys(address).length ? (
                <Card sx={{ backgroundColor: "#EBF1F4", width: "60%" }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Billing Address
                    </Typography>
                    <Typography variant="body1" sx={{}}>
                      {address.contact_name}
                    </Typography>
                    <Typography variant="body1">
                      {address.address_line1}, {address.address_line2}{" "}
                      {address.address_line3}
                    </Typography>
                    <Typography variant="body1">
                      {address.service_area}
                    </Typography>
                    <Typography variant="body1">
                      {address.landmark}, {address.pincode}
                    </Typography>
                    <Box
                      sx={{
                        gap: "10px",
                      }}
                    >
                      <Typography>
                        GST:{userProfile?.consumer_profile?.gst}
                      </Typography>
                      <Typography>
                        Bill Name:{userProfile?.consumer_profile?.billing_name}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                <Typography>Assing a billing Address</Typography>
              )}
            </Stack>

            <Stack mt={2}>
              <input
                style={{ display: "none" }}
                type="file"
                ref={uploadPoRef}
                onChange={poUploadChange}
              />
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "0.65rem",
                  backgroundColor: poFile ? "green" : "herit",
                }}
                onClick={triggerPoRef}
              >
                {poFile ? "Po Uploaded" : "Upload Po"}
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default CreatePoDialog;
