/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  TextField,
} from "@mui/material";
import {
  CropFreeTwoTone,
  BatteryFullTwoTone,
  ShowChartOutlined,
} from "@mui/icons-material";
import { MarkDamageCanCallback } from "../../../Pages/Redux/Actions/supliersAction";
import QrScanners from "../../Scanners/QrScanners";
import { GetUserDepositDetails } from "../../../Pages/Redux/Actions/adminBookingAction";

const reasonCodes = {
  QrBarcodeDamaged: 104,
  PhysicallyDamaged: 101,
  containerReceived: 1,
  constanerNotReceived: 0,
};
const MarkingDamage = ({ open, setOpen, user_id, order_id, products }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [containerReceived, setContainerReceived] = useState();
  const [receivedQty, setReceivedQty] = useState(0);
  const [qrCodes, setQrCodes] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState();
  //   const [userStock, setUserStock] = useState(products ? products : []);
  //   const userDetails = useSelector((state) => state.adminBookingData);
  const dispatch = useDispatch();
  const handleQrChange = async (qr_code) => {
    if (qr_code.trim().length < 5) {
      return;
    }
    if (qrCodes.includes(qr_code)) {
    } else {
      qrCodes.push(qr_code);
      setQrCodes([...qrCodes]);
    }
  };
  // Auto Submit on scanning
  useEffect(() => {
    if (qrCodes?.length) {
      onSubmit();
    }
  }, [qrCodes]);
  const onSubmit = async () => {
    let selectedProduct;
    if (selectedProductId) {
      selectedProduct = products.find(
        (product) =>
          `${product?.capacity}${product?.material}` === selectedProductId
      );
    }
    dispatch(
      MarkDamageCanCallback({
        qr_code: qrCodes,
        order_id: order_id,
        type_of_can: selectedReason,
        capacity: selectedProduct?.capacity,
        material: selectedProduct?.material,
        quantity: receivedQty,
        is_received: containerReceived,
      })
    ).then((data) => {
      if (data) {
        setContainerReceived();
        setReceivedQty();
      }
      setQrCodes([]);
    });
  };
  useEffect(() => {
    if (user_id) {
      dispatch(GetUserDepositDetails({ userId: user_id }));
    }
  }, [user_id]);
  //   useEffect(() => {
  //     if (!products) {
  //       setUserStock(userDetails?.userStockData || []);
  //        //@Removed/console.log(userDetails?.userStockData);
  //     }
  //   }, [userDetails?.userStockData]);

  const selectedProduct = products?.find(
    (item) => `${item?.capacity}${item?.material}` == selectedProductId
  );
  return (
    <Box
      sx={
        {
          // width: "100%",
        }
      }
    >
      <Dialog
        fullWidth
        open={open}
        PaperProps={{
          style: {
            // width: "100%",

            backgroundColor: "#f0f0f0", // Light gray background for the entire Dialog
            boxShadow: "none", // Optional: removes shadow
            borderRadius: "8px", // Rounded corners for the Dialog
          },
        }}
      >
        <DialogContent
          sx={{
            width: "100%",
            height: "700px",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            alignContent: "center",
            // justifyContent: "center",
          }}
        >
          <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
            <FormControl variant="outlined" sx={{ width: "100%" }} size="small">
              <InputLabel>Select Product</InputLabel>
              <Select
                value={selectedProductId}
                key={selectedProductId}
                onChange={(e) => setSelectedProductId(e.target.value)}
                label="Select Product"
              >
                {products?.map((item) => (
                  <MenuItem value={`${item?.capacity}${item?.material}`}>
                    {item?.capacity}
                    {item?.material}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Box>Reason for Damage?</Box>
            <RadioGroup
              defaultValue="scanner"
              key={containerReceived}
              value={selectedReason}
              onChange={(e) => {
                setContainerReceived();
                setQrCodes([]);
                setSelectedReason(parseInt(e.target.value));
              }}
              name="radio-buttons-group"
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <FormControlLabel
                  sx={{
                    borderRadius: 1,
                    border: 3,
                    borderColor: "green",
                  }}
                  value={reasonCodes.PhysicallyDamaged}
                  control={<Radio />}
                  label={
                    <>
                      <Box>
                        Physical Damage
                        <Stack position="relative">
                          <Box color="primary" aria-label="icon 1">
                            <BatteryFullTwoTone
                              sx={{
                                color: "red",
                              }}
                            />
                            {/* Your icon component here, e.g., <FavoriteIcon /> */}
                          </Box>
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                            }}
                            color="error"
                            aria-label="overlay icon"
                          >
                            <ShowChartOutlined
                              sx={{
                                color: "black",
                              }}
                            />
                            {/* Your other icon component here, e.g., <EditIcon /> */}
                          </Box>
                        </Stack>
                      </Box>
                    </>
                  }
                />
                {selectedProduct && selectedProduct.is_qr ? (
                  <FormControlLabel
                    sx={{
                      borderRadius: 1,
                      border: 3,
                      borderColor: "red",
                    }}
                    value={reasonCodes.QrBarcodeDamaged}
                    control={<Radio />}
                    label={
                      <>
                        <Box>
                          Qr/Barcode Damaged{" "}
                          <CropFreeTwoTone
                            sx={{
                              color: "red",
                            }}
                          />
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <></>
                )}
              </Box>
            </RadioGroup>
          </Box>
          {selectedReason && (
            <>
              {selectedReason !== reasonCodes.QrBarcodeDamaged &&
              selectedProduct &&
              selectedProduct.is_qr ? (
                <>
                  <QrScanners handleChange={handleQrChange} />
                  {qrCodes && (
                    <Chip
                      label={`${qrCodes.length} Container Scanned`}
                      color="success"
                    ></Chip>
                  )}
                </>
              ) : (
                <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                  <TextField
                    label="Quantity"
                    size="small"
                    key={receivedQty}
                    value={receivedQty}
                    onChange={(e) =>
                      setReceivedQty(parseInt(e.target.value) || "")
                    }
                  />
                </Box>
              )}
            </>
          )}
          {/* {qrCodes.length || selectedProductId ? (
            <Box>
              <Box>Did you receive the Can?</Box>
              <RadioGroup
                defaultValue="scanner"
                key={containerReceived}
                value={containerReceived}
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                onChange={(e) => {
                  setContainerReceived(
                    e.target.value === "false"
                      ? false
                      : e.target.value === "true"
                      ? true
                      : ""
                  );
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <FormControlLabel
                    sx={{
                      borderRadius: 1,
                      border: 3,
                      borderColor: "green",
                    }}
                    value={true}
                    control={<Radio />}
                    label={
                      <>
                        Recieved <RepeatOne color="success" />
                      </>
                    }
                  />
                  <FormControlLabel
                    sx={{
                      borderRadius: 1,
                      border: 3,
                      borderColor: "red",
                    }}
                    value={false}
                    control={<Radio />}
                    label={
                      <>
                        Not Recieved <HighlightOff color="error" />
                      </>
                    }
                  />
                </Box>
              </RadioGroup>
            </Box>
          ) : (
            <></>
          )} */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {(qrCodes.length || selectedProductId) &&
            ((!qrCodes.length && receivedQty > 0) || qrCodes.length) ? (
              <Button onClick={onSubmit} variant="contained">
                Submit
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            size="small"
            color="error"
          >
            Close
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MarkingDamage;
