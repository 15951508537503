import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import * as Material from "@mui/material";
import CustomConsole from "../../CustomConsole";
import moment from "moment";
// import moment from "moment-timezone";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EditPaymentReasonDialog from "./EditPaymentReasonDialog";
import EditPaymentDialog from "./EditPaymentDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDepositDetails,
  GetUserDepositDetails,
} from "../Redux/Actions/adminBookingAction";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import EditOrderReasonDialog from "./EditOrderReasonDialog";
import { isOptionGroup } from "@mui/base";
import EditOrderDialog from "./EditOrderDialog";
import {
  AddMoneyToWallet,
  GetUserStockDetails,
} from "../Redux/Actions/userDetailsAdminAction";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { ordersStatus } from "../../Components/AppMeta/appMetaConfig";
const styles = {
  typographyStylesHeading: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  typographyStylesRes: {
    fontSize: "13px",
  },
};

export default function OrderDetailsPaper2({
  orderDetails,
  newOrderDetails,
  adminRole,
  setOrderFlag,
  isEditOrderDialogOpen,
  setIsEditOrderDialogOpen,
  editOrderClicked,
  setEditOrderClicked,
  productorderDetails,
}) {
  const dispatch = useDispatch();
  const userDetailsUpdate = useSelector((state) => state.userDetailsUpdate);

  const currentDate = new Date();
  const setUpConfigData = useSelector((state) => state.adminBookingData);
  const [userId, setUserId] = React.useState("");
  const [userStock, setUserStock] = React.useState({});
  const [cansUserDeposited, setCansUserDeposited] = React.useState(0);
  const [cansInUse, setCansInUse] = React.useState(0);
  const [min, setMin] = React.useState("");
  const [max, setMax] = React.useState("");
  const [values, setValues] = React.useState([]);
  const [depositAmountPerCan, setDepositAmountPerCan] = React.useState(200);
  const [isReasonChoosen, setIsReasonChoosen] = React.useState(false);
  CustomConsole(currentDate);
  CustomConsole(orderDetails);
  React.useEffect(() => {
    if (Object.keys(newOrderDetails).length > 0) {
      setUserId(newOrderDetails[0]?.delivery_address.user_id);
    }
  }, [newOrderDetails]);

  React.useEffect(() => {
    if (userId !== "") {
      CustomConsole("Hitting user stock API");
      // dispatch(GetUserDepositDetails(userId));
      // dispatch(GetDepositDetails(userId));
    }
  }, [userId]);

  React.useEffect(() => {
    CustomConsole(userDetailsUpdate);
  }, [userDetailsUpdate]);

  // React.useEffect(() => {
  //   if (isReasonChoosen === true) {
  //     setIsEditOrderDialogOpen(true);
  //   }
  // }, [isReasonChoosen]);
  // React.useEffect(() => {
  //   CustomConsole("User stock data");
  //   CustomConsole(userStock);
  //   if (userStock.length > 0) {
  //     userStock.map((data) => {
  //       setUserStock(data);
  //     });
  //     setCansUserDeposited(userStock[0].total_deposited_containers_qty);
  //     setCansInUse(userStock[0].filled_container_qty);
  //   }
  // }, [userStock]);
  const paymentType = [
    {
      value: "Prepaid",
      label: "$",
    },
    {
      value: "Postpaid",
      label: "€",
    },
  ];
  const [fromValue, setFromValue] = React.useState("");
  const [toValue, setToValue] = React.useState("");

  const [isEditPaymentDialogOpen, setIsEditPaymentDialogOpen] =
    React.useState(false);
  // const [isEditOrderDialogOpen, setIsEditOrderDialogOpen] =
  //   React.useState(false);
  const [isPaymentReasonChoosen, setIsPaymentReasonChoosen] =
    React.useState(false);
  const [refundAmount, setRefunctAmount] = React.useState(0);
  const [isEditOrderReasonChoosen, setIsEditOrderReasonChoosen] =
    React.useState(false);
  const [editPaymentReason, setEditPaymentReason] = React.useState("");
  const [editOrderReason, setEditOrderReason] = React.useState("");
  const [editPaymentClicked, setEditPaymentClicked] = React.useState(false);
  // const [editOrderClicked, setEditOrderClicked] = React.useState(false);
  const [editPaymentReasonsValues, setEditPaymentReasonsValues] =
    React.useState([]);
  const [editOrderReasonValues, setEditOrderReasonValues] = React.useState([]);
  const [customReasonPay, setCustomReasonPay] = React.useState("");
  React.useEffect(() => {
    CustomConsole(
      "------------------Start and end date-----------------------"
    );
    let momentFromDate = moment
      .utc(newOrderDetails[0]?.start_date, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD");
    CustomConsole(momentFromDate);
    setFromValue(momentFromDate);
    let momentEndDate = moment
      .utc(newOrderDetails[0]?.end_date, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD");
    CustomConsole(momentEndDate);
    setToValue(momentEndDate);
  }, [orderDetails]);

  React.useEffect(() => {
    if (isPaymentReasonChoosen === true) {
      setIsEditPaymentDialogOpen(true);
    }
  }, [isPaymentReasonChoosen]);
  React.useEffect(() => {
    if (isEditOrderReasonChoosen === true) {
      setIsEditOrderDialogOpen(true);
    }
  }, [isEditOrderReasonChoosen]);

  // React.useEffect(() => {
  //   CustomConsole(setUpConfigData);q
  // }, [setUpConfigData]);

  React.useEffect(() => {
    // CustomConsole(props.orderData);
    CustomConsole(setUpConfigData);
    // setUserStock(setUpConfigData.userStockData);
    if (setUpConfigData.userStockData.length > 0) {
      setUpConfigData.userStockData.map((data) => {
        setUserStock(data);
        setCansUserDeposited(
          data.total_deposited_containers_qty +
            data.total_undeposited_containers_qty
        );
        setCansInUse(data.filled_container_qty);
      });
    }
    setUpConfigData.setupConfigData.map((data) => {
      // CustomConsole(data);
      if (data.key === "payment_edit_reason_types") {
        setEditPaymentReasonsValues(data.value);
      } else if (data.key === "order_edit_reason_types") {
        setEditOrderReasonValues(data.value);
      } else if (data.key === "cansminmax") {
        CustomConsole(data.value);
        setValues(data.value);
      }
    });
  }, [setUpConfigData]);

  React.useEffect(() => {
    CustomConsole(values);
    if (values.length) {
      setMax(values[2].v);
      setMin(values[3].v);
    }
  }, [values]);
  const handleChangeFrom = (newValue) => {
    CustomConsole(newValue);
    setFromValue(newValue);
  };

  const handleChangeTo = (newValue) => {
    setToValue(newValue);
  };
  const handlePaymentEdit = () => {
    setEditPaymentClicked(true);
    setEditPaymentReason("");
  };
  const handleEditOrder = () => {
    setEditOrderClicked(true);
    setEditOrderReason("");
  };
  const handleRefund = () => {
    // setRefunctAmount
    CustomConsole("refundAmount: ");
    CustomConsole(refundAmount);
    if (refundAmount > 0 && newOrderDetails[0]?.order_id) {
      dispatch(
        AddMoneyToWallet({
          order_id: newOrderDetails[0]?.order_id,
          amount: parseInt(refundAmount),
        })
      );
    } else {
      CustomConsole("Invalid Amount");
    }
  };
  return (
    <Card elevation={20} sx={{ height: { md: "530px", xs: "100%" } }}>
      <CardContent>
        <Box
          sx={{
            bgcolor: "#152057",
            color: "white",
            padding: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Payment Details
          </Typography>
        </Box>
        <Box>
          <Box sx={{ padding: "10px" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // paddingTop: "10px",
                }}
              >
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Transaction ID :{" "}
                </Material.Typography>

                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "end",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderDetails[0]?.payment_details.transaction_id
                    ? newOrderDetails[0]?.payment_details.transaction_id
                    : "-"}
                </Material.Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Payment Type :{" "}
                </Material.Typography>

                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "end",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderDetails[0]?.payment_details.payment_type
                    ? newOrderDetails[0]?.payment_details.payment_type
                    : "-"}
                </Material.Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Payment Status :{" "}
                </Material.Typography>

                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "end",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderDetails[0]?.payment_status_name
                    ? newOrderDetails[0]?.payment_status_name
                    : "-"}
                </Material.Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Payment Method :{" "}
                </Material.Typography>

                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "end",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderDetails[0]?.payment_method
                    ? newOrderDetails[0]?.payment_method
                    : "-"}
                </Material.Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Amount :{" "}
                </Material.Typography>

                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "end",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderDetails[0]?.payment_details.amount
                    ? newOrderDetails[0]?.payment_details.amount
                    : "-"}
                </Material.Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Payment Created:{" "}
                </Material.Typography>

                <Material.Typography
                  sx={{
                    display: "flex",
                    textAlign: "end",
                    fontWeight: "bold",
                  }}
                >
                  {newOrderDetails[0]?.payment_details.created_at
                    ? moment(
                        newOrderDetails[0]?.payment_details.created_at
                      ).format("DD-MM-YYYY")
                    : "-"}
                </Material.Typography>
              </Box>
            </Box>
            {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
            ADMIN_ROLES.BW_ADMIN === adminRole ||
            ADMIN_ROLES.BW_CSHEAD === adminRole ||
            ADMIN_ROLES.BW_CSLEAD === adminRole ||
            ADMIN_ROLES.BW_CS === adminRole ||
            ADMIN_ROLES.BW_FINANCE === adminRole ||
            ADMIN_ROLES.BW_FINANCEHEAD === adminRole ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {/* {orderDetails.payment_type !== 300 && ( */}
                {newOrderDetails[0]?.payment_method !== "POSTPAID" &&
                newOrderDetails[0]?.payment_status_name === "PENDING" ? (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={handlePaymentEdit}
                    sx={{ ...appColors.commonBtnColor, color: "whitesmoke" }}
                  >
                    Change Payment
                  </Button>
                ) : (
                  ""
                )}
                {/* )} */}
              </Box>
            ) : (
              ""
            )}
          </Box>
          {/* <Box>
            {orderDetails.order_status !== 400 ? (
              <Box sx={{ marginTop: "1em" }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleEditOrder}
                  sx={appColors.commonBtnColor}
                >
                  Edit order
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box> */}
          {newOrderDetails[0]?.order_status ===
            ordersStatus.ORDER_STATUS_REJECTED ||
          newOrderDetails[0]?.order_status ===
            ordersStatus.ORDER_STATUS_CANCELLED ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                jsutifyContent: "center",
                margin: "10px",
              }}
            >
              <TextField
                type="number"
                value={refundAmount}
                onChange={(e) => {
                  setRefunctAmount(() => parseInt(e.target.value));
                }}
              />
              <Button
                variant="outlined"
                disabled={
                  isNaN(refundAmount) || refundAmount <= 0 ? true : false
                }
                onClick={handleRefund}
              >
                Refund
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>

        <EditPaymentDialog
          isEditPaymentDialogOpen={isEditPaymentDialogOpen}
          setIsEditPaymentDialogOpen={setIsEditPaymentDialogOpen}
          setIsPaymentReasonChoosen={setIsPaymentReasonChoosen}
          orderId={newOrderDetails[0]?.order_id}
          editPaymentReason={editPaymentReason}
          // setOpenPWD={props.setOpenPWD}
          // setHitPaymentPending={props.setHitPaymentPending}
          currentPaymentMethod={newOrderDetails[0]?.payment_method}
          paymentType={newOrderDetails[0]?.payment_type}
          userId={newOrderDetails[0]?.delivery_address.user_id}
          customReasonPay={customReasonPay}
          setCustomReasonPay={setCustomReasonPay}
        />
        <EditPaymentReasonDialog
          editPaymentClicked={editPaymentClicked}
          setEditPaymentClicked={setEditPaymentClicked}
          editPaymentReasonsValues={editPaymentReasonsValues}
          setIsPaymentReasonChoosen={setIsPaymentReasonChoosen}
          setEditPaymentReason={setEditPaymentReason}
          editPaymentReason={editPaymentReason}
          setCustomReasonPay={setCustomReasonPay}
          customReasonPay={customReasonPay}
        />
        <EditOrderReasonDialog
          setEditOrderClicked={setEditOrderClicked}
          editOrderClicked={editOrderClicked}
          editOrderReasonValues={editOrderReasonValues}
          setEditOrderReason={setEditOrderReason}
          editOrderReason={editOrderReason}
          setIsEditOrderReasonChoosen={setIsEditOrderReasonChoosen}
        />
        <EditOrderDialog
          setIsEditOrderDialogOpen={setIsEditOrderDialogOpen}
          isEditOrderDialogOpen={isEditOrderDialogOpen}
          orderId={newOrderDetails[0]?.order_id}
          // setOpenPWD={props.setOpenPWD}
          orderData={newOrderDetails}
          productorderDetails={productorderDetails}
          userStock={userStock}
          cansUserDeposited={cansUserDeposited}
          setIsReasonChoosen={setIsReasonChoosen}
          cansInUse={cansInUse}
          min={min}
          max={max}
          editOrderReason={editOrderReason}
          depositAmountPerCan={depositAmountPerCan}
          setIsEditOrderReasonChoosen={setIsEditOrderReasonChoosen}
          userId={newOrderDetails[0]?.delivery_address.user_id}
          setOrderFlag={setOrderFlag}
        />
      </CardContent>
    </Card>
  );
}
