import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import OrderDetailsPaper from "./OrderDetailsPaper";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as Material from "@mui/material";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { Box, DialogActions, Drawer, Grid, Paper } from "@mui/material";
import moment from "moment";
import OrderDetailsPaper2 from "./OrderDetailsPaper2";
import CustomConsole from "../../CustomConsole";
import OrderDetailsPaper3 from "./OrderDetailsPaper3";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import RejectReasonCard from "./RejectReasonCard";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_TYPE } from "../../Components/AppMeta/appMetaConfig";
import { DELIVERY_TYPE } from "../../Components/AppMeta/appMetaConfig";
import {
  GetNewSearchOrderDetails,
  GetViewOrderDetails,
  PostForceCompleteScanning,
} from "../Redux/Actions/ordersAdminAction";

import CancelReasonCard from "./CancelOrder";
import {
  ordersStatus,
  ADMIN_ROLES,
} from "../../Components/AppMeta/appMetaConfig";
import { toast } from "react-toastify";
import { CansRollBackForOrder } from "../Redux/Actions/userDetailsAdminAction";
import UpdatedUserDetails from "./UpdatedUserDetails";
import { Tooltip } from "@material-ui/core";
import RollbackCansDialog from "../../Components/DialogBoxes/SupplierDialogBox/RollbackCansDialog";
import {
  PostSupplierProductInscan,
  PostSupplierRollBack,
  PutSupplierCurrentDayCompleteDeliveries,
  PutSupplierCurrentDayVerifyOtp,
} from "../Redux/Actions/supliersAction";
import { QrCodeScanner } from "@mui/icons-material";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";
import CompleteScanningProcessDialog from "./CompleteScanningProcessDialog";
import Compressor from "compressorjs";
import ProductsInScanDialog from "../SupplierDeliveries/ProductsInScanDialog";
import CurrentDayDeliveriesEnterOtpDialog from "../../Components/DialogBoxes/SupplierDialogBox/CurrentDayDeliveriesEnterOtpDialog";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditOrderDetailsDialog({
  isEditOrderDetailsDialog,
  newOrderDetails,
  setIsEditOrderDetailsDialog,
  orderDetails,
  setOrderDetails,
  setpageReferesh,
  adminRole,
  updateDeliveryFuncCall,
  setOrderFlag,
}) {
  CustomConsole(newOrderDetails);
  const dispatch = useDispatch();
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);

  const RejectDetailsData = useSelector((state) => state.orderDetailsAdmin);
  const ConfigData = useSelector((state) => state.adminBookingData);
  CustomConsole(orderDetails);
  const [orderStatus, setOrderStatus] = React.useState(false);
  const [RejectFilterDialog, setRejectFilterDialog] = React.useState(false);
  const [openCompleteScaningDialog, setOpenCompleteScaningDialog] =
    React.useState(false);
  const [driverChallanUrl, setDriverChallanUrl] = React.useState("");
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [uploadDriverChallanFile, setUploadDriverChallanFile] =
    React.useState("");
  const [CancelFilterDialog, setCancelFilterDialog] = React.useState(false);
  const [productData, setProductData] = React.useState({});
  const [openProductsInScanDialog, setOpenProductsInScanDialog] =
    React.useState(false);
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [uploadDriverChallanBtn, setUploadDriverChallanBtn] =
    React.useState(false);
  const [productorderDetails, setProductorderDetails] = React.useState({});
  const fileHandler = useSelector((state) => state.fileHandler);
  const [products, setProducts] = React.useState([]);
  CustomConsole(products);
  const [isOrderStarted, setIsOrderStarted] = React.useState(false);
  const [isEditOrderDialogOpen, setIsEditOrderDialogOpen] =
    React.useState(false);
  const [editOrderClicked, setEditOrderClicked] = React.useState(false);
  const [editBtnFlag, seteditBtnFlag] = React.useState(false);
  const [Rejectdata, setRejectdata] = React.useState([]);
  const [Canceldata, setCanceldata] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState([]);
  const [openRollBackDialog, setOpenRollBackDialog] = React.useState(false);
  const [openEnterOtpDialog, setOpenEnterOtpDialog] = React.useState(false);
  const [otpValidationError, setOtpValidationError] = React.useState(false);
  const [enterOtpTextfied, setEnterOtpTextfied] = React.useState("");
  const [isProductScanCompleted, setIsProductScanCompleted] =
    React.useState(false);
  CustomConsole(productDetails);
  const handleCancelClose = () => {
    setCancelFilterDialog(true);
  };
  const handleClose = () => {
    setIsEditOrderDetailsDialog(false);
    allOrderData.newallOrderDetails = {};
  };
  const handleRejectCard = (event) => {
    event.preventDefault();
    setRejectFilterDialog(true);
    // toast.dark("Implement ")
  };
  const uploadDriverCgallan = React.useRef(null);

  const handleUploadDriverDriverChallan = () => {
    uploadDriverCgallan.current.click();
  };
  React.useEffect(() => {
    CustomConsole(newOrderDetails);
    newOrderDetails &&
    newOrderDetails[0]?.order_status !== undefined &&
    newOrderDetails[0]?.order_status === ordersStatus.ORDER_STATUS_DELIVERED
      ? setOrderStatus(true)
      : setOrderStatus(false);
    if (newOrderDetails) {
      setIsOrderStarted(
        newOrderDetails[0]?.packaged_drinking_water !== undefined &&
          newOrderDetails[0]?.packaged_drinking_water.is_order_started
      );
    }
    if (newOrderDetails[0]?.packaged_drinking_water?.is_invalid === false) {
      const data = [
        {
          orderdetails: newOrderDetails,
          products: newOrderDetails[0]?.products,
        },
      ];
      // data.push({pdw:orderDetails.packaged_drinking_water})
      setProductDetails(data);
    }

    CustomConsole("--------------Product Details--------------------");
    let extraReturnEmptyTwantyLtr =
      newOrderDetails[0]?.extra_return_products?.find(
        (data) =>
          data.capacity ===
            newOrderDetails[0]?.packaged_drinking_water
              ?.selected_container_capacity &&
          data.material ===
            newOrderDetails[0]?.packaged_drinking_water
              ?.selected_container_material
      );
    console.log(extraReturnEmptyTwantyLtr);
    let localProducts = [];
    let scanningCompletedProducts = 0;
    let scannedProductCount = 0;
    let isOrderStartedStatus = newOrderDetails[0]?.packaged_drinking_water
      ?.is_order_started
      ? true
      : false;
    let order_status = newOrderDetails[0]?.order_status;
    if (!newOrderDetails[0]?.packaged_drinking_water?.is_invalid) {
      // localProducts.push({ data: orderDetails });
      CustomConsole("-----------localProducts-------------------");
      CustomConsole(localProducts);
      localProducts.push({
        capacity:
          newOrderDetails[0]?.packaged_drinking_water
            ?.selected_container_capacity,
        material:
          newOrderDetails[0]?.packaged_drinking_water
            ?.selected_container_material,
        consumer_damaged_qty:
          newOrderDetails[0]?.packaged_drinking_water?.consumer_damaged_qty,
        extra_empty_return: extraReturnEmptyTwantyLtr?.quantity
          ? extraReturnEmptyTwantyLtr?.quantity
          : 0,
        booking_type:
          newOrderDetails[0]?.packaged_drinking_water?.booking_type !==
            undefined &&
          newOrderDetails[0]?.packaged_drinking_water?.booking_type !== null
            ? newOrderDetails[0]?.packaged_drinking_water?.booking_type === 2
              ? "One Time Sale"
              : "Refill"
            : "Refill",
        is_qr: true,
        order_qty:
          newOrderDetails[0]?.packaged_drinking_water?.twentyltr_capacity_qty,
        return:
          newOrderDetails[0]?.packaged_drinking_water?.empty_container_qty,
        received_empty:
          newOrderDetails[0]?.packaged_drinking_water?.received_empty_cans,
        submitted_filled:
          newOrderDetails[0]?.packaged_drinking_water?.submitted_filled_cans,
        sku_qty: 1,
        product_price:
          newOrderDetails[0]?.packaged_drinking_water?.product_price,
        deposit_amount:
          newOrderDetails[0]?.packaged_drinking_water?.deposit?.price,
        deposit_qty:
          newOrderDetails[0]?.packaged_drinking_water?.deposit?.quantity,
        sub_total_price:
          newOrderDetails[0]?.packaged_drinking_water?.sub_total_price,
        final_price: newOrderDetails[0]?.packaged_drinking_water?.final_price,
        delivery_charges:
          newOrderDetails[0]?.packaged_drinking_water?.extra_charges?.price,
        order_status: order_status,
        scan_type:
          isOrderStartedStatus &&
          newOrderDetails[0]?.packaged_drinking_water?.is_inscan_completed ==
            false
            ? 1
            : isOrderStartedStatus &&
              newOrderDetails[0]?.packaged_drinking_water
                ?.is_inscan_completed == true &&
              newOrderDetails[0]?.orderDetails?.packaged_drinking_water
                ?.is_outscan_completed == false
            ? 2
            : 0,
      });
      if (
        newOrderDetails[0]?.packaged_drinking_water?.is_inscan_completed &&
        newOrderDetails[0]?.packaged_drinking_water?.is_outscan_completed
      ) {
        scannedProductCount = scannedProductCount + 1;
      }
      if (
        localProducts?.packaged_drinking_water?.is_inscan_completed === true &&
        localProducts?.packaged_drinking_water?.is_outscan_completed === true
      ) {
        scanningCompletedProducts = 1;
      }
    }
    if (newOrderDetails[0]?.products?.length > 0) {
      for (const data of newOrderDetails[0]?.products) {
        // localProducts.push({
        //   data: data,
        // });
        const extraReturnEmptyOtherProducts =
          newOrderDetails[0]?.extra_return_products?.find(
            (extra) =>
              extra?.capacity === data.capacity &&
              extra?.material === data.material
          );
        const orderQty = data.quantity * data.sku_qty;
        const retQty = data.return;
        const isInscan = retQty > data.consumer_submitted_empty;
        const isOutScan = orderQty > data.consumer_received_filled;

        localProducts.push({
          capacity: data.capacity,
          material: data.material,
          consumer_damaged_qty: 0,
          extra_empty_return: extraReturnEmptyOtherProducts?.quantity
            ? extraReturnEmptyOtherProducts?.quantity
            : 0,
          is_qr: data.is_qr,
          order_qty: data.quantity,
          return: data.return,
          booking_type:
            data.booking_type !== undefined && data?.booking_type !== null
              ? data.booking_type === 2
                ? "One Time Sale"
                : "Refill"
              : "Refill",
          received_empty: data.consumer_submitted_empty,
          submitted_filled: data.consumer_received_filled,
          sku_qty: data.sku_qty,
          product_price: data.product_price,
          deposit_amount: data.deposit?.price,
          deposit_qty: data.deposit?.quantity,
          final_price: data.final_price,
          sub_total_price: data.sub_total_price,
          delivery_charges: data.extra_charges?.price,
          order_status: order_status,
          scan_type:
            isOrderStartedStatus && isInscan
              ? 1
              : isOrderStartedStatus && !isInscan && isOutScan
              ? 2
              : 0,
        });
        if (
          orderQty === data.consumer_received_filled &&
          retQty === data.consumer_submitted_empty
        ) {
          scannedProductCount = scannedProductCount + 1;
        }
        if (
          data.return * data.sku_qty !== data.consumer_submitted_empty &&
          data.quantity * data.sku_qty !== data.consumer_received_filled
        ) {
          scanningCompletedProducts = scanningCompletedProducts + 1;
        }
      }
    }
    if (scannedProductCount === localProducts.length) {
      setIsProductScanCompleted(true);
    }
    CustomConsole("---------localProducts-------");
    CustomConsole(localProducts);
    setProducts(localProducts);
  }, [newOrderDetails]);

  React.useEffect(() => {
    if (uploadDriverChallanFile !== "") {
      new Compressor(uploadDriverChallanFile, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          //@Removed/console.log(uploadDriverChallanFile);
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          //@Removed/console.log(compressedResult);
          //@Removed/console.log(compressedResult.name);
          dispatch(UploadFile(compressedResult));
          setUploadDriverChallanBtn(true);
          setUploadDriverChallanFile("");
          setUploadFlags(true);
        },
      });
    }
  }, [uploadDriverChallanFile]);
  React.useEffect(() => {
    CustomConsole(fileHandler);
    CustomConsole("------------fileHandler-------------");
    CustomConsole(fileHandler);
    if (
      fileHandler.isFileUploaded === true &&
      uploadDriverChallanBtn === true
    ) {
      CustomConsole("Delivery Challan file uploaded file here");
      setDriverChallanUrl(fileHandler.fileName);
      setUploadDriverChallanBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Delivery Challan uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);
  React.useEffect(() => {
    if (ConfigData.setupConfigData.length > 0) {
      ConfigData.setupConfigData.map((data, key) => {
        if (data.key === "reject_reason_types") {
          setRejectdata(data.value);
        }
        if (data.key === "pdw_consumer_order_cancel") {
          setCanceldata(data.value);
        }
      });
    }
  }, [RejectFilterDialog, CancelFilterDialog]);
  const orderStatuss = (order_status) => {
    switch (order_status) {
      case ordersStatus.ORDER_STATUS_NEW_REQUEST:
        return "NEW REQUEST";
      case ordersStatus.ORDER_STATUS_CANCELLED:
        return "CANCELLED";
      case ordersStatus.ORDER_STATUS_ACCEPTED:
        return "ACCEPTED";
      case ordersStatus.ORDER_STATUS_REJECTED:
        return "REJECTED";
      case ordersStatus.ORDER_STATUS_DELIVERED:
        return "DELIVERED";
      default:
        return "";
    }
  };

  const handleEditOrder = () => {
    CustomConsole("------------------handleEditOrder--------------");
    setEditOrderClicked(true);
  };

  const handelEditBtn = () => {
    CustomConsole("------------------handelEditBtn--------------");
    seteditBtnFlag(true);
  };

  const handleClickRollBack = () => {
    CustomConsole("----handleClickRollBackCans------");
    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
    };
    dispatch(CansRollBackForOrder(reqObj));
  };
  const orderqty =
    newOrderDetails[0]?.packaged_drinking_water !== undefined
      ? newOrderDetails[0]?.packaged_drinking_water.twentyltr_capacity_qty
      : "-";
  const deliveryCharges =
    newOrderDetails[0]?.packaged_drinking_water
      ?.current_same_day_delivery_charge || "--";

  const handleClickSinglrProduct = (data) => {
    setProductorderDetails(data);
    setIsEditOrderDialogOpen(true);
  };
  const handleClickYes = (props) => {
    CustomConsole("------handleClickYes--------");
    CustomConsole(props);

    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
      products: [
        {
          capacity: props.productorderDetails.capacity,
          material: props.productorderDetails.material,
          is_crate: false,
        },
      ],
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierRollBack(reqObj));
    setOpenRollBackDialog(false);
    setIsProductScanCompleted(false);

    setTimeout(() => {
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 30,
        page_number: 1,
        order_ids: [newOrderDetails[0]?.order_id],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
    }, 1000);
  };

  const handleClickNo = () => {
    CustomConsole("------handleClickNo--------");
    setOpenRollBackDialog(false);
  };
  const handleOpenRollBackSinglrProduct = (event, data) => {
    event.stopPropagation();
    CustomConsole("----------handleOpenRollBackSinglrProduct------------");
    CustomConsole(data);
    setProductorderDetails(data);
    setOpenRollBackDialog(true);
  };
  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          CustomConsole(position);
          // lat = position.coords.latitude;
          // longit = position.coords.longitude;
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    CustomConsole("latitude:" + latitude);
    CustomConsole("longitude:" + longitude);
  }, []);

  const handleStartOrderOtp = () => {
    if (
      newOrderDetails[0]?.packaged_drinking_water !== undefined &&
      newOrderDetails[0]?.packaged_drinking_water.delivery_slots !==
        undefined &&
      newOrderDetails[0]?.packaged_drinking_water.delivery_otp_status !== 200 &&
      newOrderDetails[0]?.packaged_drinking_water !== undefined &&
      newOrderDetails[0]?.packaged_drinking_water.delivery_slots !==
        undefined &&
      newOrderDetails[0]?.packaged_drinking_water.is_order_started === false
    ) {
      setOpenEnterOtpDialog(true);
    } else if (
      newOrderDetails[0]?.packaged_drinking_water !== undefined &&
      newOrderDetails[0]?.packaged_drinking_water.delivery_slots !==
        undefined &&
      newOrderDetails[0]?.packaged_drinking_water.delivery_otp_status === 200 &&
      newOrderDetails[0]?.packaged_drinking_water !== undefined &&
      newOrderDetails[0]?.packaged_drinking_water.delivery_slots !==
        undefined &&
      newOrderDetails[0]?.packaged_drinking_water.is_order_started === false
    ) {
      const reqObj = {
        delivery_id: newOrderDetails[0]?.delivery_id,
        delivery_otp: newOrderDetails[0]?.deliveryOtp,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
      setTimeout(() => {
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [newOrderDetails[0]?.order_id],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, 1000);
    }
  };
  const handleOpenEnterOtpDialog = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          CustomConsole(position);
          // lat = position.coords.latitude;
          // longit = position.coords.longitude;
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    CustomConsole("latitude:" + latitude);
    CustomConsole("longitude:" + longitude);

    const reqObj = {
      delivery_id: newOrderDetails[0]?.delivery_id,
      delivery_challan: fileHandler?.fileName || "",
      latitude: latitude || undefined,
      longitude: longitude || undefined,
    };
    CustomConsole(reqObj);
    if (longitude !== "" && latitude !== "") {
      dispatch(PutSupplierCurrentDayCompleteDeliveries(reqObj));
      setTimeout(() => {
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [newOrderDetails[0]?.order_id],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, 1000);
    } else {
      CustomConsole("---------select Location--------------");
      toast.error("Please Enable Location", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleOpenCompleteScaningDialog = () => {
    setOpenCompleteScaningDialog(true);
  };
  const handleCompleteScanning = (props) => {
    const reqObj = {
      order_id: newOrderDetails[0]?.order_id,
      reason: props.reason,
    };
    if (props.reason !== "") {
      dispatch(PostForceCompleteScanning(reqObj));
      setOpenCompleteScaningDialog(false);
      setTimeout(() => {
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [newOrderDetails[0]?.order_id],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, 1000);
    } else {
      toast.error("Please Enter Reason For Force Complete Scanning", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleCloseScanning = () => {
    setOpenCompleteScaningDialog(false);
  };
  const handleCloseInScan = () => {
    setOpenProductsInScanDialog(false);
  };
  const handleInScanDialog = (data) => {
    CustomConsole("-----setProductData-----------");
    CustomConsole(data);
    setProductData(data);
    setOpenProductsInScanDialog(true);
  };
  const handleProductInScan = (data) => {
    CustomConsole(data);
    const reqObj = {
      order_id: data.order_id,
      capacity: data.capacity,
      material: data.material,
      quantity: data.quantity,
    };
    CustomConsole(reqObj);
    dispatch(PostSupplierProductInscan(reqObj));
    setOpenProductsInScanDialog(false);
    setTimeout(() => {
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 30,
        page_number: 1,
        order_ids: [newOrderDetails[0]?.order_id],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
    }, 1000);
  };

  const handleCurrentDaySubmitOtp = () => {
    // CustomConsole(props);
    CustomConsole("---handleCurrentDaySubmitOtp---");
    setOpenEnterOtpDialog(false);
    if (enterOtpTextfied !== "") {
      const reqObj = {
        delivery_id: newOrderDetails[0]?.delivery_id,
        delivery_otp: enterOtpTextfied,
      };
      CustomConsole(reqObj);
      dispatch(PutSupplierCurrentDayVerifyOtp(reqObj));
      setEnterOtpTextfied("");
      setTimeout(() => {
        const reqObj = {
          delivery_from_date: moment().format("YYYY-MM-DD"),
          delivery_to_date: moment().format("YYYY-MM-DD"),
          page_size: 30,
          page_number: 1,
          order_ids: [newOrderDetails[0]?.order_id],
        };
        dispatch(GetNewSearchOrderDetails(reqObj));
      }, 1000);
    } else {
      toast.error("Please Enter OTP", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleCloseDialog = () => {
    CustomConsole("---handleCloseDialog---");
    setOpenEnterOtpDialog(false);
  };
  return (
    <div>
      {newOrderDetails && Object.keys(newOrderDetails).length !== 0 ? (
        <>
          {" "}
          <Dialog
            open={isEditOrderDetailsDialog}
            fullScreen
            onClose={handleClose}
            TransitionComponent={Transition}
            anchor="right"
          >
            <AppBar sx={{ position: "sticky" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon sx={{ bgcolor: "red" }} />
                </IconButton>
                <Typography
                  sx={{ ml: 1, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Order Details
                </Typography>
                <Typography sx={{ ml: 1, flex: 1 }}>
                  {newOrderDetails[0]?.order_id}
                </Typography>

                {ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                ADMIN_ROLES.BW_ADMIN === adminRole ||
                ADMIN_ROLES.BW_CSHEAD === adminRole ||
                ADMIN_ROLES.BW_CS === adminRole ||
                ADMIN_ROLES.BW_CSLEAD === adminRole ? (
                  <>
                    {newOrderDetails[0]?.order_status ==
                      ordersStatus.ORDER_STATUS_NEW_REQUEST ||
                    newOrderDetails[0]?.order_status ===
                      ordersStatus.ORDER_STATUS_ACCEPTED ? (
                      <Button
                        autoFocus
                        color="error"
                        variant="contained"
                        sx={{ color: "white" }}
                        onClick={handleCancelClose}
                      >
                        Cancel Order
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  " "
                )}

                {/* {orderDetails.order_status === 100 &&
                (ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                  ADMIN_ROLES.BW_ADMIN === adminRole ||
                  ADMIN_ROLES.BW_CSHEAD === adminRole ||
                  ADMIN_ROLES.BW_CS === adminRole ||
                  ADMIN_ROLES.BW_CSLEAD === adminRole) ? (
                  <Button
                    autoFocus
                    color="error"
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={handleRejectCard}
                  >
                    Reject Order
                  </Button>
                ) : (
                  " "
                )} */}
              </Toolbar>
            </AppBar>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={4}>
                <Box>
                  <Paper
                    sx={{ padding: "10px", height: "100%" }}
                    elevation={20}
                  >
                    <Box
                      sx={{
                        bgcolor: "#152057",
                        color: "white",
                        padding: "6px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Order Details
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "10px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          // padding: "10px",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Order Id :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {/* {orderDetails.order_id} */}
                          {newOrderDetails[0]?.order_id}
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Consumer Name :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {/* {orderDetails.consumer_name} */}
                          {newOrderDetails[0]?.consumer_name}
                        </Material.Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Order Type :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                            color:
                              newOrderDetails[0]?.order_type === "INSTA"
                                ? "red"
                                : newOrderDetails[0]?.order_type === "POSTPAID"
                                ? "#5DADE2"
                                : newOrderDetails[0]?.order_type ===
                                  "SUBSCRIBED"
                                ? "#808B96"
                                : "#CA6F1E",
                          }}
                        >
                          {newOrderDetails[0]?.order_type}
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Order Status :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                            color:
                              newOrderDetails[0]?.order_status ===
                              ordersStatus.ORDER_STATUS_ACCEPTED
                                ? "#27AE60"
                                : newOrderDetails[0]?.order_status ===
                                  ordersStatus.ORDER_STATUS_CANCELLED
                                ? "red"
                                : newOrderDetails[0]?.order_status ===
                                  ordersStatus.ORDER_STATUS_NEW_REQUEST
                                ? "blue"
                                : newOrderDetails[0]?.order_status ===
                                  ordersStatus.ORDER_STATUS_REJECTED
                                ? "#6E2C00"
                                : newOrderDetails[0]?.order_status ===
                                  ordersStatus.ORDER_STATUS_DELIVERED
                                ? "green"
                                : "",
                          }}
                        >
                          {/* {orderStatuss(orderDetails.order_status)} */}
                          {newOrderDetails[0]?.order_status_name}
                        </Material.Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          OTP :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.delivery_otp}
                        </Material.Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Water Price :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.trip_rate}
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Discount :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.discount}
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Total Price :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.final_price}
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Payment Type :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.payment_type_name}
                        </Material.Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Booking Date :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.booking_date}
                          <br />
                          {newOrderDetails[0]?.booking_time}
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Delivery Date :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.delivery_date}
                          <br />
                          {newOrderDetails[0]?.delivered_on}
                        </Material.Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Applied Water drops :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {
                            newOrderDetails[0]?.packaged_drinking_water
                              ?.water_drop_details?.water_drops
                          }
                        </Material.Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Coupon Code :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.packaged_drinking_water
                            ?.coupon_code_details?.selected
                            ? newOrderDetails[0]?.packaged_drinking_water
                                ?.coupon_code_details?.selected
                            : "--"}
                        </Material.Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "start",
                            color: "blue",
                            fontWeight: "bold",
                            // paddingLeft: "70px",
                          }}
                        >
                          Cancel Reason :{" "}
                        </Material.Typography>

                        <Material.Typography
                          sx={{
                            display: "flex",
                            textAlign: "end",
                            fontWeight: "bold",
                            // paddingRight: "70px",
                          }}
                        >
                          {newOrderDetails[0]?.cancel_reason}
                        </Material.Typography>
                      </Box>
                    </Box>
                    {newOrderDetails[0]?.order_status === 200 &&
                    newOrderDetails[0]?.packaged_drinking_water
                      ?.is_order_started === false ? (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {" "}
                        <Material.Button
                          variant="contained"
                          sx={{
                            // background: "red",
                            color: "white",
                            borderRadius: "10px",
                            mt: "10px",
                            height: "30px",
                          }}
                          onClick={handleStartOrderOtp}
                        >
                          start order
                        </Material.Button>
                      </Box>
                    ) : (
                      ""
                    )}
                    {newOrderDetails[0]?.packaged_drinking_water
                      ?.is_order_started === true &&
                    newOrderDetails[0]?.order_status === 200 &&
                    isProductScanCompleted === false ? (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {" "}
                        <Material.Button
                          variant="contained"
                          sx={{
                            background: "red",
                            color: "white",
                            borderRadius: "10px",
                            mt: "10px",
                            height: "30px",
                          }}
                          onClick={handleOpenCompleteScaningDialog}
                        >
                          <QrCodeScanner />
                          Complete Scanning
                        </Material.Button>
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "10px",
                      }}
                    >
                      {newOrderDetails[0]?.payment_type === 300 &&
                      newOrderDetails[0]?.order_status === 200 &&
                      isProductScanCompleted ? (
                        <Button
                          onClick={handleUploadDriverDriverChallan}
                          variant="outlined"
                          disabled={
                            fileHandler.isFileUploaded !== undefined &&
                            fileHandler.isFileUploaded === false
                              ? false
                              : true
                          }
                        >
                          {fileHandler.isFileUploaded !== undefined &&
                          fileHandler.isFileUploaded === false
                            ? "Upload Delivery Challan"
                            : "Uploaded Successfully"}
                          <input
                            type="file"
                            accept="image/*"
                            // capture="”camera"
                            ref={uploadDriverCgallan}
                            style={{ display: "none" }}
                            onChange={(e) =>
                              setUploadDriverChallanFile(e.target.files[0])
                            }
                          />
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                    {newOrderDetails[0]?.order_status === 200 &&
                    isProductScanCompleted ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Material.Button
                          variant="contained"
                          sx={{
                            background: appColors.commonBtnColor.background,
                            color: "white",
                            borderRadius: "10px",
                            mt: "10px",
                            height: "30px",
                          }}
                          onClick={handleOpenEnterOtpDialog}
                        >
                          Complete Delivery
                        </Material.Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Paper>
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={4}>
                <Paper
                  sx={{ height: { md: "530px", xs: "100%" } }}
                  elevation={20}
                >
                  <Box>
                    <Box
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        marginBottom: "10px",
                        // marginTop: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "#152057",
                          color: "white",
                          padding: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Delivery Address
                        </Typography>
                      </Box>
                      {/* <Box>
                    <Typography sx={{ textAlign: "left" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Delivery Contact Name
                      </span>
                      : {orderDetails.delivery_address.contact_name}
                    </Typography>
                  </Box> */}
                      <Box sx={{ padding: "10px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // paddingTop: "10px",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                              // paddingLeft: "70px",
                            }}
                          >
                            Delivery Contact Name :{" "}
                          </Material.Typography>

                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "end",
                              fontWeight: "bold",
                              // paddingRight: "70px",
                            }}
                          >
                            {newOrderDetails[0]?.delivery_address.contact_name}
                          </Material.Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                              // paddingLeft: "70px",
                            }}
                          >
                            Address :{" "}
                          </Material.Typography>

                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "end",
                              fontWeight: "bold",
                              // paddingRight: "70px",
                            }}
                          >
                            {`${newOrderDetails[0]?.delivery_address.address_line1}, ${newOrderDetails[0]?.delivery_address.address_line2}, ${newOrderDetails[0]?.delivery_address.address_line3}`}
                          </Material.Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                              // paddingLeft: "70px",
                            }}
                          >
                            Pincode :{" "}
                          </Material.Typography>

                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "end",
                              fontWeight: "bold",
                              // paddingRight: "70px",
                            }}
                          >
                            {newOrderDetails[0]?.delivery_address.pincode}
                          </Material.Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                              // paddingLeft: "70px",
                            }}
                          >
                            Service Area :{" "}
                          </Material.Typography>

                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "end",
                              fontWeight: "bold",
                              // paddingRight: "70px",
                            }}
                          >
                            {newOrderDetails[0]?.delivery_address.service_area}
                          </Material.Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "start",
                              color: "blue",
                              fontWeight: "bold",
                              // paddingLeft: "70px",
                            }}
                          >
                            Delivery Contact Number :{" "}
                          </Material.Typography>

                          <Material.Typography
                            sx={{
                              display: "flex",
                              textAlign: "end",
                              fontWeight: "bold",
                              // paddingRight: "70px",
                            }}
                          >
                            {newOrderDetails[0]?.delivery_address.contact_phone}
                          </Material.Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12} xs={12} md={4}>
                {/* {orderStatus && ( */}
                <OrderDetailsPaper2
                  orderDetails={orderDetails}
                  newOrderDetails={newOrderDetails}
                  productorderDetails={productorderDetails}
                  adminRole={adminRole}
                  setOrderFlag={setOrderFlag}
                  isEditOrderDialogOpen={isEditOrderDialogOpen}
                  setIsEditOrderDialogOpen={setIsEditOrderDialogOpen}
                  editOrderClicked={editOrderClicked}
                  setEditOrderClicked={setEditOrderClicked}
                />
                {/* )} */}
              </Grid>

              <Grid item sm={12} xs={12} md={12}>
                <Box>
                  <Paper
                    sx={{ padding: "10px", height: "100%" }}
                    elevation={20}
                  >
                    <Box
                      sx={{
                        bgcolor: "#152057",
                        color: "white",
                        padding: "6px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Product Details
                      </Typography>
                    </Box>
                    <Material.TableContainer sx={{ maxHeight: 440 }}>
                      <Material.Table>
                        <Material.TableHead sx={{ bgcolor: "#6495ED" }}>
                          <Material.TableRow>
                            <Material.TableCell
                              size="small"
                              align="center"
                              sx={{ color: "white" }}
                            >
                              Edit Order
                            </Material.TableCell>

                            <Material.TableCell sx={{ color: "white" }}>
                              Product Type
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Capacity
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Material
                            </Material.TableCell>
                            <Material.TableCell
                              sx={{ color: "white", width: "250px" }}
                            >
                              Order Qty(Pack)
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Return Qty(units)
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Sku
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Consumer Damaged
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Scanned Empty
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Scanned Filled
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Booking Type
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Extra Empty
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Deposited Qty
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Deposited Price
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Product Price
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Sub Total Price
                            </Material.TableCell>
                            <Material.TableCell sx={{ color: "white" }}>
                              Delivery Charges
                            </Material.TableCell>
                            <Material.TableCell
                              size="small"
                              align="center"
                              sx={{ color: "white" }}
                            >
                              RollBack
                            </Material.TableCell>
                            <Material.TableCell
                              size="small"
                              align="center"
                              sx={{ color: "white" }}
                            >
                              In-Scan
                            </Material.TableCell>
                            {/* <Material.TableCell sx={{ color: "white" }}>
                              Final Price
                            </Material.TableCell> */}
                          </Material.TableRow>
                        </Material.TableHead>
                        <Material.TableBody>
                          {products !== undefined &&
                          products !== null &&
                          products.length > 0 ? (
                            <>
                              {products?.map((data) => {
                                return (
                                  <Material.TableRow>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                    >
                                      {data.order_status === 200 ? (
                                        <Material.Button
                                          onClick={() =>
                                            handleClickSinglrProduct(data)
                                          }
                                          size="small"
                                          variant="contained"
                                          sx={{ width: "100px" }}
                                        >
                                          edit Order
                                        </Material.Button>
                                      ) : (
                                        ""
                                      )}
                                    </Material.TableCell>

                                    <Material.TableCell
                                    // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.is_qr === true
                                        ? "Qr Product"
                                        : "Qr Less Product"}
                                    </Material.TableCell>

                                    <Material.TableCell
                                    // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.capacity}
                                    </Material.TableCell>
                                    <Material.TableCell
                                    // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.material}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      sx={{
                                        textAlign: "center",
                                        width: "250px",
                                      }}
                                    >
                                      {/* {data.order_qty} */}
                                      {data.order_qty}
                                      <br />({data.order_qty} * Pack Of{" "}
                                      {data.sku_qty}={" "}
                                      {data.order_qty * data.sku_qty})
                                    </Material.TableCell>
                                    <Material.TableCell
                                      // sx={{ color: "#CDE3FC" }}
                                      size="small"
                                      align="center"
                                    >
                                      {data.return}
                                    </Material.TableCell>
                                    <Material.TableCell
                                    // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.sku_qty}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      // sx={{ color: "#CDE3FC" }}
                                      size="small"
                                      align="center"
                                    >
                                      {data.consumer_damaged_qty}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                      // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.received_empty
                                        ? data.received_empty
                                        : 0}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                      // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.submitted_filled
                                        ? data.submitted_filled
                                        : 0}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                    >
                                      {data.booking_type}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                    >
                                      {data.extra_empty_return}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      // sx={{ color: "#CDE3FC" }}
                                      size="small"
                                      align="center"
                                    >
                                      {data.deposit_qty}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                      // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.deposit_amount}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                      // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.product_price
                                        ? data.product_price
                                        : 0}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      // sx={{ color: "#CDE3FC" }}
                                      size="small"
                                      align="center"
                                    >
                                      {data.sub_total_price
                                        ? data.sub_total_price
                                        : 0}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      // sx={{ color: "#CDE3FC" }}
                                      size="small"
                                      align="center"
                                    >
                                      {data.delivery_charges
                                        ? data.delivery_charges
                                        : 0}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                    >
                                      {data.received_empty > 0 ||
                                      data.submitted_filled > 0 ||
                                      data.consumer_damaged_qty > 0 ? (
                                        <Material.Button
                                          variant="contained"
                                          size="small"
                                          onClick={(event) =>
                                            handleOpenRollBackSinglrProduct(
                                              event,
                                              data
                                            )
                                          }
                                          sx={{ width: "100px" }}
                                        >
                                          Roll Back
                                        </Material.Button>
                                      ) : (
                                        ""
                                      )}
                                    </Material.TableCell>
                                    <Material.TableCell
                                      size="small"
                                      align="center"
                                    >
                                      {data.order_status === 200 &&
                                      data.scan_type == 1 ? (
                                        <Material.Button
                                          variant="contained"
                                          size="small"
                                          onClick={() =>
                                            handleInScanDialog(data)
                                          }
                                          sx={{ width: "100px" }}
                                        >
                                          complete
                                        </Material.Button>
                                      ) : (
                                        ""
                                      )}
                                    </Material.TableCell>
                                    {/* <Material.TableCell
                                    // sx={{ color: "#CDE3FC" }}
                                    >
                                      {data.final_price}
                                    </Material.TableCell> */}
                                  </Material.TableRow>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}

                          {newOrderDetails[0]?.packaged_drinking_water?.damage_invoice?.map(
                            (data) => {
                              return (
                                <Material.TableRow>
                                  <Material.TableCell>
                                    consumer damaged product
                                  </Material.TableCell>
                                  <Material.TableCell>--</Material.TableCell>
                                  <Material.TableCell>
                                    {data.capacity}
                                  </Material.TableCell>
                                  <Material.TableCell>
                                    {data.material}
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    {" "}
                                    {data.quantity}
                                    <br />({data.quantity} * Pack Of{" "}
                                    {data.sku_qty}={" "}
                                    {data.quantity * data.sku_qty})
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    {data.sku_qty}
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    {data.quantity}
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    --
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    {data.product_price}
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    {data.sub_total_price}
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    0
                                  </Material.TableCell>
                                  <Material.TableCell
                                    size="small"
                                    align="center"
                                  >
                                    --
                                  </Material.TableCell>
                                  <Material.TableCell>
                                    completed
                                  </Material.TableCell>
                                </Material.TableRow>
                              );
                            }
                          )}
                        </Material.TableBody>
                      </Material.Table>
                    </Material.TableContainer>
                  </Paper>
                </Box>
              </Grid>
              <Grid item sm={12} xs={12} md={4}>
                <Paper
                  sx={{ height: { md: "340px", xs: "100%" }, padding: "10px" }}
                  elevation={20}
                >
                  <Box
                    sx={{
                      bgcolor: "#152057",
                      color: "white",
                      padding: "6px",
                      // margin: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Distributor Details
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "10px",
                      alignItems: "center",
                      margin: "10px",
                    }}
                  >
                    {/* <Typography sx={{ fontWeight: "bold" }}>
                      Supplier Name:
                      <span style={{ fontWeight: "bold" }}>
                        {orderDetails.supplier_name}
                      </span>
                    </Typography> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                          // paddingLeft: "70px",
                        }}
                      >
                        Name{" "}
                      </Material.Typography>

                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "end",
                          fontWeight: "bold",
                          // paddingRight: "70px",
                        }}
                      >
                        {newOrderDetails[0]?.supplier_name}
                      </Material.Typography>
                    </Box>
                    {/* 
                    {newOrderDetails[0]?.order_status !== 400 && (
                      <Button>
                        <RefreshIcon
                          // onClick={() =>
                          //   dispatch(GetViewOrderDetails(orderDetails.order_id))
                          // }
                          onClick={handleRefresh}
                        ></RefreshIcon>
                      </Button>
                    )} */}
                    <Box>
                      {newOrderDetails[0]?.order_status ===
                        ordersStatus.ORDER_STATUS_ACCEPTED ||
                      (newOrderDetails[0]?.order_status ===
                        ordersStatus.ORDER_STATUS_NEW_REQUEST &&
                        (ADMIN_ROLES.BW_SUPERADMIN === adminRole ||
                          ADMIN_ROLES.BW_ADMIN === adminRole ||
                          ADMIN_ROLES.BW_CSHEAD === adminRole ||
                          ADMIN_ROLES.BW_SALESHEAD === adminRole ||
                          ADMIN_ROLES.BW_OPSHEAD === adminRole ||
                          ADMIN_ROLES.BW_CS === adminRole ||
                          ADMIN_ROLES.BW_CSLEAD === adminRole ||
                          ADMIN_ROLES.BW_OPS === adminRole)) ? (
                        <Box>
                          <Button
                            variant="contained"
                            onClick={handelEditBtn}
                            size="small"
                            sx={appColors.commonBtnColor}
                          >
                            Reassign
                          </Button>
                        </Box>
                      ) : (
                        " "
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontWeight: "bold", m: 1 }}>
                      DRIVER DETAILS
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        // flexDirection: "row",
                        // justifyContent: "space-between",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                          // paddingLeft: "70px",
                        }}
                      >
                        Driver Name{" "}
                      </Material.Typography>

                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "end",
                          fontWeight: "bold",
                          // paddingRight: "70px",
                        }}
                      >
                        {newOrderDetails[0]?.driver_name
                          ? newOrderDetails[0]?.driver_name
                          : "--"}
                      </Material.Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          color: "blue",
                          fontWeight: "bold",
                          paddingLeft: "70px",
                        }}
                      >
                        Driver Phone{" "}
                      </Material.Typography>

                      <Material.Typography
                        sx={{
                          display: "flex",
                          textAlign: "end",
                          fontWeight: "bold",
                          paddingRight: "70px",
                        }}
                      >
                        {newOrderDetails[0]?.driver_phone
                          ? newOrderDetails[0]?.driver_phone
                          : "--"}
                      </Material.Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={12} xs={12} md={4}>
                <OrderDetailsPaper
                  orderDetails={orderDetails}
                  newOrderDetails={newOrderDetails}
                  setpageReferesh={setpageReferesh}
                  adminRole={adminRole}
                  updateDeliveryFuncCall={updateDeliveryFuncCall}
                  setOrderFlag={setOrderFlag}
                  setIsOrderStarted={setIsOrderStarted}
                  isOrderStarted={isOrderStarted}
                  editBtnFlag={editBtnFlag}
                  seteditBtnFlag={seteditBtnFlag}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={4}>
                {/* {orderStatus && ( */}
                <UpdatedUserDetails
                  newOrderDetails={newOrderDetails}
                  orderDetails={orderDetails}
                  setOrderFlag={setOrderFlag}
                />
                {/* )} */}
              </Grid>
              <Grid item sm={12} xs={12} md={4}>
                {orderStatus && (
                  <OrderDetailsPaper3
                    newOrderDetails={newOrderDetails}
                    orderDetails={orderDetails}
                    setOrderFlag={setOrderFlag}
                  />
                )}
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "20px",
                flexWrap: "wrap",
              }}
            ></Box>

            <DialogActions></DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Dialog>
        </>
      ) : (
        ""
      )}
      {RejectFilterDialog ? (
        <>
          <RejectReasonCard
            newOrderDetails={newOrderDetails}
            RejectFilterDialog={RejectFilterDialog}
            setRejectFilterDialog={setRejectFilterDialog}
            orderDetails={orderDetails}
            Rejectdata={Rejectdata}
            setOrderFlag={setOrderFlag}
          />
        </>
      ) : (
        ""
      )}
      {openRollBackDialog && (
        <RollbackCansDialog
          openRollBackDialog={openRollBackDialog}
          handleNo={handleClickNo}
          handleYes={handleClickYes}
          productorderDetails={productorderDetails}
        />
      )}
      {CancelFilterDialog ? (
        <>
          <CancelReasonCard
            newOrderDetails={newOrderDetails}
            CancelFilterDialog={CancelFilterDialog}
            setCancelFilterDialog={setCancelFilterDialog}
            orderDetails={orderDetails}
            Canceldata={Canceldata}
            setOrderFlag={setOrderFlag}
          />
        </>
      ) : (
        ""
      )}
      {openCompleteScaningDialog && (
        <CompleteScanningProcessDialog
          openCompleteScaningDialog={openCompleteScaningDialog}
          handleCloseScanning={handleCloseScanning}
          handleCompleteScanning={handleCompleteScanning}
        />
      )}

      {openProductsInScanDialog && (
        <ProductsInScanDialog
          openProductsInScanDialog={openProductsInScanDialog}
          handleClose={handleCloseInScan}
          hadleSubmitInScan={handleProductInScan}
          inScanData={productData}
          orderid={newOrderDetails[0]?.order_id}
        />
      )}
      {openEnterOtpDialog && (
        <CurrentDayDeliveriesEnterOtpDialog
          openEnterOtpDialog={openEnterOtpDialog}
          enterOtpTextfied={enterOtpTextfied}
          otpValidationError={otpValidationError}
          setOtpValidationError={setOtpValidationError}
          setEnterOtpTextfied={setEnterOtpTextfied}
          handleSubmitOTP={handleCurrentDaySubmitOtp}
          handleClose={handleCloseDialog}
        />
      )}
    </div>
  );
}
