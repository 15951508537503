import React from "react";
import CustomConsole from "../../CustomConsole";
import * as Material from "@mui/material";

function ReduceWalletAmountDialog(props) {
  const [reduceWalletTransActionId, setReduceWalletTransActionId] =
    React.useState("");
  const [reasonForReduceWaterWallet, setReasonForReduceWaterWallet] =
    React.useState("");
  const [amountToReduceWaterWallet, setAmountToReduceWaterWallet] =
    React.useState(null);
  const handleReduceWalletAmount = () => {
    CustomConsole("------------handleReduceWalletAmount-------------");
    props.handleReduceWalletAmount({
      reduceWalletTransActionId: reduceWalletTransActionId,
      setReduceWalletTransActionId: setReduceWalletTransActionId,
      reasonForReduceWaterWallet: reasonForReduceWaterWallet,
      amountToReduceWaterWallet: amountToReduceWaterWallet,
      setReasonForReduceWaterWallet: setReasonForReduceWaterWallet,
      setAmountToReduceWaterWallet: setAmountToReduceWaterWallet,
    });
  };
  const handleCloseReduceWalletAmount = () => {
    props.handleCloseReduceWalletAmount();
  };
  const handleChangeReduceWallet = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid number or floating point number
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setAmountToReduceWaterWallet(inputValue);
    }
  };
  return (
    <Material.Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        },
      }}
      open={props.openReduceWalletAmount}
    >
      <Material.DialogContent>
        <Material.Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Reduce Water Wallet
        </Material.Typography>
        <Material.Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Material.TextField
            id="outlined-basic"
            label="Enter amount"
            variant="outlined"
            color="secondary"
            type="number"
            value={amountToReduceWaterWallet}
            size="small"
            onChange={handleChangeReduceWallet}
          />

          <Material.TextField
            id="outlined-basic"
            label="Reason For Reduce Wallet *"
            variant="outlined"
            color="secondary"
            value={reasonForReduceWaterWallet}
            size="small"
            onChange={(event) =>
              setReasonForReduceWaterWallet(event.target.value)
            }
          />

          <Material.TextField
            label="Transaction Id*"
            variant="outlined"
            color="secondary"
            value={reduceWalletTransActionId}
            size="small"
            onChange={(event) =>
              setReduceWalletTransActionId(event.target.value)
            }
          />
        </Material.Box>
        <Material.Box
          sx={{ display: "flex", gap: "10px", m: 1, justifyContent: "center" }}
        >
          <Material.Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleCloseReduceWalletAmount}
          >
            close
          </Material.Button>
          <Material.Button
            variant="contained"
            size="small"
            onClick={handleReduceWalletAmount}
          >
            Reduce Now
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}

export default ReduceWalletAmountDialog;
