import { AddUserAddressAdmin } from "./userDetailsAdminAction";
const actionType = {
  SPINNER_LOADING: "Action for the Spinner Loading",
  GET_CAN_AGE_DETAILS: "GetCanAgeDetails",
  GET_CAN_AGE_DETAILS_SEND_EMAIL: "GetCanAgeDetailsSendEmail",
  POST_RETURN_CAN_REQUEST: "CrateWithdarawalRequest",
  SPINNER_LOADED: "Action for the Spinner loaded",
  SIGN_IN: "POST: LogIn",
  RESET_PASSWORD_VERIFY: "POST: ResetPasswordVerifyEmail",
  FORGOT_PASSWORD_USER_VERIFY_OTP: "PUT: forgetpasswordverify",
  GET_USER_PROFILE: "getUserProfile",
  GET_WFS_DETAILS: "getWfsDetails",
  GET_FILES: "getFiles",
  UPLOAD_FILE: "uploadFiles",
  UPLOAD_QUALITY_FILE: "uploadQualityFile",
  GET_WFS_ONGOING_ORDERS: "getWfsOngoingOrder",
  GET_WFS_COMPLETED_ORDERS: "getWfsCompletedOrder",
  GET_WFS_ALL_ORDERS: "getWfsAllOrders",
  VERIFY_OTP: "verifyOtp",
  GET_WFS_ONGOING_BATCH: "getWfsOngoingBatch",
  GET_WFS_ONGOING_LINE_DETAILS: "GetWfsOngoingBatchLineDetails",
  PUT_WFS_QR_LINK: "putWfsQrlinkAction",
  PUT_WFS_START_BATCH: "PutStartBatchAction",
  PUT_WFS_STOP_BATCH: "PutStopBatchAction",
  PUT_WFS_CREATE_BATCH: "putWfsCreateBatch",
  ADMIN_ADD_USER: "adminAddUser",
  REFUND_CONSUMER_STOCK: "PostRefundConsumerStock",
  SEARCH_USER_ADMIN: "getSearchAdminUser",
  GET_APP_SETTINGS: "getAdminAppSettings",
  UPDATE_APP_SETTINGS: "updateAppSettings",
  DEPOSIT_USER_STOCK: "DepositUserStock",
  ADD_DISTRIBUTOR: "addDistributor",
  GET_DISTRIBUTOR_DETAILS: "getDistributorDetails",
  UPDATE_USER_DETAILS: "updateUserDetails",
  UPDATE_GST_DETAILS: "updateGstDetails",
  GET_SUBSCRIPTION_DETAILS: "getSubscriptionDetails",
  GET_USER_ADDRESS_ADMIN: "getUserAddressAdmin",
  GET_ACCOUNTS_DETAILS_ADMIN: "getAccountDetailsAdmin",
  DISABLE_USER_ADMIN: "disableUserAdmin",
  ENABLE_USER_ADMIN: "enableUserAdmin",
  DELETE_USER: "deleteUser",
  EDIT_USER_ADDRESS_ADMIN: "editUserAddressAdmin",
  DELETE_USER_ADDRESS_ADMIN: "deleteUserAddressAdmin",
  ADD_USER_ADDRESS_ADMIN: "AddUserAddressAdmin",
  UPDATE_USER_STOCK: "updateUserStocks",
  RETURN_CANS_ORDER: "returnCansOrder",
  REDUCE_USER_STOCK: "reduceUserStocks",
  GET_SEARCH_ORDER_DETAILS: "getSearchOrder",
  GET_VIEW_ORDER_DETAILS: "getViewOrderDetails",
  GET_DELIVERY_SLOTS_LIST: "getDeliverySlotsList",
  GET_DELIVERY_SLOTS: "getDeliverySlots",
  ADD_DELIVERY_SLOTS: "addDeliverySlots",
  GET_POSTPAID_ORDERS: "getPostpaidOrders",
  GET_WALLET_DETAILS: "getWalletDetails",
  POSTPAID_ORDER: "postpaidOrder",
  REJECT_POSTPAID_ORDER: "rejectPostpaidOrder",
  UPDATE_POSTPAID_PRICE: "updatePostpaidPrice",
  GET_SUPPLIER_ADMIN: "getSupplierAdmin",
  SIGN_OUT: "signOut",
  GET_IOT_DEVICES: "getIotDevices",
  GET_IOT_DEVICE: "getIotDevice",
  UPDATE_IOT_DEVICE: "updateIotDevice",
  ADD_IOT_DEVICE: "addIotDevice",
  GET_LOSTCANS_LIST: "getLostCansList",
  SEND_NOTIFICATION_USERS: "sendNotificationUsers",
  DISABLE_USER_CANS: "disableUserCans",
  GET_BOOKNOW_MESSAGE: "getBookNowMessage",
  GET_BANKDOWNTIME_MESSAGE: "getBankdowntimeMessage",
  GET_SETUP_SETTINGS: "getSetupSetting",
  UPDATE_BOOKNOW_MESSAGE: "updateBookNowMessage",
  UPDATE_BANKDOWNTIME_MESSAGE: "updateBankDateMessage",
  UPDATE_SETUP_CONFIG: "updateSetupConfig",
  GET_ONGOING_ORDER_ADMIN: "getOnGoingOrderAdmin",
  GET_COMPLETED_ORDER_ADMIN: "getCompletedOrderAdmin",
  GET_ALL_ORDER_ADMIN: "getAllOrderAdmin",
  GET_USER_DETAILS: "getUserDetails",
  PUT_START_SUBMISSION_ONGOING_ADMIN: "StartSubmissinOngoingWfsAdmin",
  GET_CONTAINER_DETAILS: "getContainerDetails",
  UPDATE_CONTAINER_DETAILS: "updateContainerDetails",
  GET_DEPOSIT_LIST: "getDepositList",
  UPDATE_ACTIVE_DEACTIVE_CANS: "updateActiveDepositCans",
  CANCEL_ORDER: "CancelOrderDetails",
  GET_PINCODE_AREAS: "getPincodeAreas",
  GET_BOOKNOW_DETAILS: "bookNowDetails",
  GET_DEPOST_DETAILS: "getDepositDetails",
  GET_USER_DEPOST_DETAILS: "getUserDepositDetails",
  GET_CONFIG_DATA: "getConfigData",
  BOOK_PWD_ORDER: "bookPWDOrder",
  BOOK_POSTPAID: "bookingPostpaid",
  GET_WFS_DETAILS_ADMIN: "getWfsDetails",
  UPDATE_WFS_DETAILS: "updateWfsDetails",
  CREATE_BANNER_TYPE: "createBannerTypes",
  UPDATE_BANNER_TYPE: "updateBannerTypes",
  GET_BANNER_TYPES: "getBannerTypes",
  GET_PARTICULAR_BANNER_TYPE: "getParticularBannerType",
  CREATE_BANNERS: "createBanners",
  UPDATE_BANNERS: "updateBanners",
  GET_ALL_BANNERS: "getAllBanners",
  GET_PARTICULAR_BANNER_DETAILS: "getParticularbannerDetails",
  GET_BANNERTYPE_LIST: "getBannerTypeList",
  BOOK_PDW_SUBSCRIBE_ORDER: "bookPwdSubscribeOrder",
  UPDATE_SUBSCRIPTION_ORDER: "updateSubscriptionOrder",
  GET_PROFILE_DETAILS: "getProfileDetails",
  GET_SUPPLIER_DRIVER_DETAILS: "getSupervisorDriverDetails",
  GET_USERSTOCK_DATA: "getUserStockData",
  GET_DEPOSIT_DATA: "getUserDeposits",
  UPDATE_SUPPLIER_DETAILS: "updateSupplierDetails",
  GET_ASSIGNED_WFS: "getAssignedWFS",
  GET_WFS_LINE_DETAILS: "getWFSLineDetails",
  ADD_WFS_LINE: "addWfsLine",
  GET_WATERFILLING_STATIONS: "getWaterFillingStations",
  ASSIGN_WFS_USER: "assignWfsUser",
  EMPTY_CANS_ORDER: "emptyCansOrder",
  UPLOAD_PO_FILE: "uploadPoFile",
  CANCEL_REASON_ORDER: "cancelOrder",
  GET_WITHDRAWAL_LIST: "getWithdrawallist",
  GET_WITHDRAWAL_ORDERID: "GetWithdrawallistOrderid",
  POST_WITHDRAWALDETAILS_REFUND: "PostWithdrawalDetailsrefund",
  PUT_WITHRAWALDETAILS_REJECTED: "PutWithdrawalDetailsReject",
  GET_WITHDRAWALDETAILS_FIRSTACCEPT: "GetWithrawalDetailsFirstAction",
  GET_WITHDRAWALDETAILS_SECONDACCEPT: "GetWithdrawalDetailsSecondAction",
  POST_WITHDRAWALDETAILS_DIALOG_ASSIGN: "AssignDialogAdmin",
  GET_QR_DETAILS: "getQrDetails",
  GET_CONTAINER_DETAILS: "getCompleteQrDetails",
  GENERATE_QR_CODE: "generateQrCode",
  GENERATE_BAR_CODE: "generateBarcode",
  REGENERATE_QRBAR_CODE: "regenerateQrBarCode",
  GET_QR_LIST: "getQrList",
  LINK_QR_BARCODE: "linkQrbarcodeDetails",
  IN_SCAN_EMPTY: "inScanEmptyCans",
  OUT_SCAN_FILLED: "outScanFilledCans",
  //done by Lebansty Valan for distributors page to assign delivery area
  ASSIGNING_DELIVERING_AREA: "assingingDeliveyArea",
  GETTING_PDW_DETAILS: "gettingPDWDetails",
  UPDATING_PDW_RATE: "updatingPDWRate",
  NOT_CONFIRMED_ORDER_DET: "notConfirmedOrderDeteails",
  ASSIGNING_SUPPLIERS: "AssigningSuppliers",
  DOWNLOAD_EMAIL_INVOICE: "downloadEmailInvoice",
  REJECT_REASON_ORDER: "RejectReasonOrder",
  GET_PINCODE_DETAILS: "GetPinCodeDeltails",
  POST_SUPPLIER_DETAILS: "postSupplierDetails",
  Regect_Reason_Details: "RegectReasonDetails",
  GET_EMPTY_CAN_ORDER: "getEmptyCanOrder",
  GET_EMPTY_CAN_BY_ORDER: "getEmptyCanByOrderId",
  REJECT_EMPTY_CAN_REQ: "rejectEmptyCanReq",
  GET_WFS_DETAILS_TO_REJECT: "getWFSDetailsToReject",
  ACCEPT_EMPTY_CAN_REQ: "acceptEmptyCanReq",
  DISABLE_WFS_SWITCH: "DisableAssignWfsSwitch",
  GET_USER_CANS_ROLLBACK: "GetCansRollBack",
  PUT_CANS_ROLLBACK: "PutCansRollBack",
  CANS_ROLLBACK_ORDER: "cansRollBack",
  ADDING_NEW_WFS: "addingNewWaterFillingStation",
  TO_GET_OTP: "gettingDeliveriesOtp",
  UPDATING_TRANS_RATE: "updatingTransRate",
  REMOVING_TRANS_RATE: "removingTransRate",
  EDIT_ORDER_USER: "editOrder",
  GET_REPLACEMENT_CAN_ORDERS: "gettingReplacementCanOrders",
  GET_ORDER_USING_ID: "getReplacementCanById",
  GET_SUPPLIER_DETAILS_REPLACE: "supplierDetailsForReplacement",
  ACCEPTING_REPLACEMENT_ORDER: "acceptingReplacementOrder",
  REJECTING_REPLECEMENT_ORDER: "rejectingReplecementOrder",
  PUT_REGULAR_ORDERS_SUPPLIER_DETAILS: "RegularOrdersAssignSupplier",
  // EDIT_ORDER_USER: "editOrderUser",
  CANCEL_GROUPING_PRODUCT: "CancelGroupProduct",
  REQUEST_REPLACEMENT_CAN: "requestingReplacementCan",
  GET_REPORT_DETAILS: "getreportdetails",
  GET_REPORT_DETAILSBY_PHONE: "getreportdetailsbyphone",
  GET_REPORT_BY_TYPE: "getreportdetailsbytype",
  GET_ROLES_LIST: "getRolesList",
  ADD_NEW_ADMIN_ROLE: "addNewAdminRole",
  GET_ADMIN_ROLE_LIST: "getAdminRoleList",
  EDIT_ADMIN_ROLES: "editAdminRoles",
  GET_POSTPAID_ORDERS_BYPHONE: "getpostpaidorderbyphoneno",
  UPDATE_ALLPOSTPAID_PRICE: "updateallpostpaidlist",
  POST_PROMO_ADD: "PostPromoCodeAddAction",
  PUT_PROMO_UPDATE: "PutPromoCodeUpdateAction",
  GET_PROMO_SINGLE_DETAIL: "GetSinglePromoCodedetailsAction",
  GET_ALL_PROMO_DETAILS: "GetAllPromoCodeListAction",
  UPDATE_REFERRAL_CODE: "UpdateReferralCodeSettingAction",
  GET_REFERRAL_SETTINGS: "getReferralSettings",
  UPDATE_REFERRAL_SETTINGS: "POST: UpdateReferral Configurations",
  DAMAGE_CANS_DATA: "damageCansData",
  DAMAGE_CAN_ACCEPT: "damageCanAccept",
  DAMAGE_CANS_REJECT: "damageCanReject",
  GET_INFLUENCER_DETAILS: "getInfluencerDetails",
  GET_INFLUENCER_REQUESTED_DETAILS: "getInfluencerRequestedDetails",
  ENABLE_INFLUENCER: "enableInfluencer",
  UPDATE_USER_ROLEID: "updateUserRoleId",
  ADD_WALLET_MONEY: "addWalletMoney",
  INFULENCER_ENABLE_DISABLE: "influencerEnableDisable",
  MAKE_USER_INFLUENCER: "makeUserInfluencer",
  GET_REFERRAL_CODES: "getReferralCodes",
  DELIVERY_SLOT: "DeliverySlotSettingAction",
  GET_DELIVERY_SLOT: "GetDeliverySlotSettingAction",
  DELIVERY_SLOTS_ENABLED_DISABLED: "DeliverySlotsEnableDisableSwitchAction",
  GETTING_GRAPH_DATA: "gettingGraphData",
  CHANGE_DELIVERY_DATE: "toChangeDeliveryDate",
  PAY_THROUGH_WALLET: "payThroughWallet",
  GET_CRATE_DETAILS: "GetCompleteCrateQrDetails",
  // Settlements Action types
  REQUEST_SETTLEMENTS: "POST: requestForSettlements",
  GET_USER_SETTLEMENT_DETAILS: "GET: requestForSettlementDetails",
  GET_DETAILS_BY_DATE: "getDetailsBydate",
  WFS_INFLUENCER_SUPPLIER_SETTLEMENTS: "wfsInfluencerSupplierSettlements",
  SETTLEMENT_HISTORY: "settlementHistory",
  WATER_DROP_SETTLEMENT: "requestForSettlement",
  SETTELMENT_REQUEST_LIST: "settlementRequestList",
  SETTLEMENT_REQUEST_REJECT: "settlementRequestReject",
  // Pincode action types
  ADDING_NEW_PINCODE: "addingNewPincode",
  GETTING_ACTIVE_PINCODES: "gettingActivePincodes",
  ADDING_NEW_SERVICEAREA: "addingNewServiceArea",
  SEARCH_AREA_PINCODES: "searchAreaPincodes",
  EDIT_PINCODE_AREA_DATA: "editPincodeAreadata",
  // Bank IFSC code verification
  GET_BANK_DETAILS_IFSC: "getBankDetailsIFSC",
  // Transactions
  GET_PAYMENT_HISTORY: "getPaymentHistory",
  GET_DEPOSIT_HISTORY: "getDepositHistory",
  GET_WATERDROPS_TRANSACTIONS: "getWaterDropTransactions",
  GET_USER_WATERDROPS: "getUserWaterDrops",
  GET_WALLET_TRANSACTIONS: "getWalletTransactions",
  //supplierActon
  POST_LOGIN_SUPPLIER: "PostLoginSupplier",
  GET_CURRENT_DAY_DELIVERS: "CurrentDayDeliveries",
  GET_AVAILABLE_DELIVERY_SLOTS: "GetAvailableDeliverySlots",
  GET_NEXT_DAY_DELIVERS: "GettingNextDayDeliveries",
  GET_COMPELETED_DELIVERIES: "GetCompletedDeliveries",
  POST_REQUEST_SLOT_FOR_FILLING: "PostRequestSlotForFilling",
  GET_SUPPLIER_WFS_ONGOING_ORDERS: "GetSupplierWfsOngoingOrders",
  GET_SUPPLIER_WFS_COMPLETED_ORDERS: "GetSupplierWfsCompletedOrders",
  GET_SUPPLIER_WFS_All_ORDERS: "GetSupplierWfsAllOrders",
  GET_SUPPLIER_REPLACEMENT_CAN_ORDERS: "GetSupplierReplacementCanOrders",
  PUT_SUPPLIER_REPLACEMENT_VERIFY_OTP: "PutSupplierReplacemtVerifyOtp",
  PUT_IN_SCAN_EMPTY_CANS: "PutLinkInScanQrBarCode",
  PUT_OUT_SCAN_FILLED_CANS: "PutSupplierOutScanFilledCans",
  GET_SUPPLIER_RETURN_CAN_ORDERS: "GetSupplierReturnCanOrders",
  PUT_SUPPLIER_RETURN_CAN_VERIFY_OTP: "PutSupplierReturnCanVerifyOtp",
  PUT_RETURN_IN_SCAN_QR_CODE_CANS: "PutSupplierReturnInScanCans",
  PUT_RETURN_IN_SCAN_BAR_CODE_CANS: "PutSupplierReturnInScanCans",
  PUT_SUPPLIER_ONGOING_START_SUBMISSION: "PutSupplierOngoingStartSubmission",
  SUPPLIER_WAREHOUSE_STOCK_SCANNING: "SupplierWarehouseStockScan",
  GET_SUPPLIER_COMPANY_PROFILE: "GetSupplierCompanyProfile",
  GET_SUPPLIER_USER_STOCK_: "GetSupplierUserStockDetails",
  GET_SUPPLIER_DELIVERY_AGENT_LIST: "GetSupplierDeliveryAgentDetails",
  GET_SUPPLIER_WAREHOUSE_EMPLOYEES: "GetWarehouseWithEmployees",
  MAP_EMPLOYEES_TO_WAREHOUSE: "MapEmployeeToWarehouse",
  GET_SUPPLIER_DELIVERY_AGENT_LIST_ADMIN:
    "GetSupplierDeliveryAgentDetailsForAdmin",
  GET_SUPPLIER_EMPLOYEES_LIST_ADMIN: "GetSupplierEmployeesListAdmin",
  POST_SUPPLER_ADD_DELIVERY_AGENT: "PostSupplierAddDeliveryAgent",
  PUT_SUPPLIER_UPDATE_DELIVERY_AGENT: "PutSupplierUpdateDeliveryAgent",
  POST_SUPPLIER_CHANGE_PASSWORD: "PostSupplierChangePassword",
  PUT_SUPPLIER_ASSIGN_MULTI_DELIVERY_SLOTS: "PutSupplierAddDelivertSlots",
  PUT_SUPPLIER_CURRENT_DELIVERIES_VERIFY_OTP: "PutSupplierCurrentDayVerifyOtp",
  PUT_SUPPLIER_CURRENTDAYDAY_DELIVERIES_OUTSCAN:
    "PutSupplierCurrentDayDeliveriesOutScan",
  PUT_SUPPLIER_CURRENTDAYDAY_COMPELETE_DELIVERT:
    "putCurrentDayCompleteDeliveries",
  PUT_SUPPLIER_CURRENTDAYDAY_DELIVERIES_INSCAN:
    "PutSupplierCurrentDayDeliveriesInScan",
  GET_ASSIGN_DELIVERY_AGENT: "GetAssignDeliveryAgent",
  POST_SUPPLIER_ASSIGN_DELIVERY_AGENT: "PostSupplierAssignDeliveryAgent",
  POST_SUPPLIER_ROLLBACK_CANS: "PostSupplierRollBack",
  GET_SUPPLIER_USER_COMPANY_PROFILE: "GetSupplierUserCompanyProfile",
  POST_SUPPLER_ASSIGN_DELIVERY_AGENT_RETURN_REPLACEMENT:
    "PostSupplierAssignDeliveryAgentReturnOrReplacement",
  REFRESH_SUPPLIER_REDUCER: "REFRESH_SUPPLIER_REDUCER",
  TRANSFER_CANS_DISTRIBUTOR_CREATE_ORDER: "TansferDistributorCanCreateOrder",
  GET_SUPPLIER_TRANSFER_CANS_ORDER_LIST: "GetSupplierTransferCansOrderList",
  GET_SUPPLIER_TRANSFER_CANS_SUPPLIER_LIST: "GetTransferCansSupplierList",
  PUT_TRANSFER_CANS_ACCEPT_REJECT: "PutTransferAcceptReject",
  GET_SUPPLIER_TRANSFER_ORDER_LIST: "GetSupplierTansferCanOrder",
  PUT_SUPPLIER_TRANSFER_CANS_VERIFY_OTP: "PutSupplierTransferCansVerifyOtp",
  GET_SUPPLIER_PREVIOUS_ONGONING_DELIVERIES:
    "GetSupplierPreviousOnGoingDeliveries",
  POST_SUPPLIER_PRODUCT_IN_SCAN: "PostSupplierProductInscan",
  POST_SUPPLIER_PRODUCT_OUT_SCAN: "PostSupplierProductOUtscan",
  POST_SUPPLIER_PRODUCT_QR_LESS_OUT_SCAN: "PostSupplierProductQrLessOutScan",
  POST_SUPPLIER_CRATE_OUT_SCAN: "PostSupplierCrateOUtscan",
  //Dashboard Admin Home page
  GETTING_SUPPLIER_ORDERS_DATA: "GetSuppliersOrderData",
  GETTING_DRIVER_ORDERS_DATA: "GetDriverOrderData",
  GET_B2B_B2C_REVENUE: "GET: GetB2BB2CRevenue Details",
  GET_B2B_QUANTITY_REVENUE: "GET: GetB2BQuantityRevenue Details",
  GET_B2C_QUANTITY_REVENUE: "GET: GetB2CQuantityRevenue Details",
  GETTING_ACTIVE_SUPPLIER_LIST_GRAPH_DATA: "GetActiveSupplierListGraphData",
  GETTING_REVENUE_SALES_B2B_B2C_GRAPH_DATA:
    "GetRevenueSalesBussinessVsConsumerGraphData",
  GETTING_ACTIVE_DRIVER_LIST: "Get the Active Driver List based on Supplier ID",
  GETTING_REVENUE_B2B_B2C_GRAPH_DATA: "GetRevenueB2BB2CGraphData",
  //wfs
  PUT_EDIT_WFS_ORDER: "PutEditEmptyCanOrder",
  GET_WFS_COMPLETED_BATCH_DETAILS: "GetWfsCompletedBatchDetails",
  PUT_WFS_EDIT_BATCH_DETAILS: "PutWfsEditBatchDetails",
  GET_RETURN_CAN_ORDERS: "GetDistributorReturnCanOrders",
  PUT_RETURN_CAN_VERIFY_OTP: "ReturnCanVerifyOtp",
  PUT_WFS_EDIT_BATCH: "WfsEditBatchAction",
  //user
  ADD_WATER_DROPS: "AddWaterDrops",
  REDUCE_WATER_WALLET_MONEY: "PostReduceWaterWallet",
  //BeatPlan
  GET_PINCODE_ORDER_COUNTS: "GetPincodeOrdersCount",
  GET_PINCODE_ORDERS_DETAILS: "GetPincodeOrderDetailsAction",
  GET_BEAT_PLAN_WFS_STOCK_DETAILS: "GetBeatPlanWfsStockDetails",
  //Marketing
  // GET_SALES_REP_DETAILS:"GetSalesRepDetails",
  GET_PRO_INDIA_SALES_REVENUE_DETAILS: "GetProIndiaSalesRevenueDetails",
  GET_PRO_INDIA_CAMPAIGN_DETAILS: "GetProIndiaCampaignDetails",
  POST_PRO_INDIA_REWARD_CUSTOMER: "PostProIndiaRewardCustomers",
  GET_PO_TRACK_DETAILS: "GetPoTrackDetails",
  POST_PO_REQUEST: "PostPoRequest",
  GET_SUPPLIERS_PINCODE: "GetSuppliersPincode",
  PO_ACCEPT_REJECT: "PoAcceptReject",
  POSTPAID_ORDER_PLACE: "PostPaidOrderPlace",
  POSTPAID_SETTLEMENT_INVOICES: "Postpaidinvoices",
  POSTPAID_INVOICES_DETAILS: "Postpaidpricebreakdown",
  POSTPAID_SETTLE: "Postpaidsettle",
  PAY_THROUGH_WALLET_PP: "walletPayPostPaid",
  GET_BILLING_ADDRESS: "Getbillingaddress",
  GET_USERS_NAME: "GetUsersBasedName",
  GETTING_ORDER_TIMING: "GetOrderTimingData",
  GET_CAMP_DETAILS: "GetTheCampaignDetails",
  PLAY_A_GAME: "PlayAGameForConsumer",
  PLAYED_GAME_DETAILS: "GetTheListOfPlayedGameDetails",
  GET_USER_WITH_REFERRAL: "GetTheUserDetailsWithReferraalCode",
  GAME_APPROVE_REJECT: "ApproveOrRejectTheGamePlayedByUser",
  PROMOTER_EMAIL_VERIFY: "POST: verifyPromotersEMailId",
  PROMOTER_LOGIN: "POST: logInAsPromoter",
  PROMOTERS_DETAILS_LIST: "GET: listOfPromotersDetails",
  GAMIFICATION_ERROR: "updateTheReducerErrorState",

  // Linking new
  GET_QR_BARCODE_DETAILS: "getQrBarcodeDetails",
  INSCAN_QR_BARCODE: "inScanQRBarcode",
  OUTSCAN_QR_BARCODE: "outScanQRBarcode",

  INVOICE_GENERATION: "invoiceGeneration",
  POSTPAID_GENERATE_INVOICE: "createInvoice",
  UPDATE_GENERATION: "updateInvoiceProgress",
  SEND_EMAIL_POSTPAID: "emailToConsumer",

  // Bulk linking
  VALIDATE_SCANNED_CODES: "validateBulkLinking",
  LINK_VALID_CANS: "linkValidCans",
  LINK_EMPTY_INSCAN_VALID_CANS: "linkEmptyInscanValidCans",
  LINK_EMPTY_OUTSCAN_VALID_CANS: "linkEmptyOutscanValidCans",
  CLEAR_VALIDATED_DATA: "clearValidatedData",
  // transaction details
  GET_TRANSACTION_DETAILS: "getTransactionDetails",

  //postpaid
  UPDATE_POSTPAID_ORDER: "updatingPostPaidPoless",
  //New Supplier Deliveries
  GET_ONGOING_ASSIGN_SLOT_AGENT_DELIVERIES:
    "GetSupplierOnGoingAssignedSlotAndAgent",
  GET_ONGOING_ASSIGN_DELIVERIES_COUNT: "GetSupplierOnGoingAssignedOrderCount",
  GET_ONGOING_ASSIGN_DELIVERIES_STATUS: "GetSupplierOnGoingAssignedOrderStatus",
  GET_ONGOING_ASSIGN_UNASSINGED_SLOT_AGENT_DELIVERIES:
    "GetSupplierAssignUnassigedPincodeSlotAgent",
  GET_ONGOING_UNASSINGED_SLOT_AGENT_DELIVERIES: "GetSupplierUnassigedSlotAgent",
  GET_SUPPLIERS_PINCODES: "GetSuppliersPincodes",
  GET_SUPPLIERS_COMPLETED_DELIVERIES: "GetSupplierCompletedDeliveriesNew",
  // Clear files
  CLEAR_FILES_DATA: "clearFilesData",
  //Can Swaping
  POST_QRBARCODE_SWAP_REQUEST: "PostQrBarcodeSwapRequest",
  GET_QRBARCODE_SWAP_LIST: "GetQrBarcodeSwapList",
  GET_QRBARCODE_SWAP_CAN_BY_ORDERID: "GetQrBarcodeSwapCanByBulkQr",
  PUT_QRBARCODE_SWAP_ACCEPT_REJECT: "PutQrBarcodeSwapAcceptOrReject",
  EDIT_DELIVERY_CHALLAN: "edit_delivery_challan",
  POSTPAID_CHALLAN_ZIP: "downloadChallanZip",
  DELETE_DRIVER_DIST: "deleteDriver",
  INVOICE_ITEMIZED_GENERATION: "itemizedInvoiceGeneration",
  ITEM_UPDATE_GENERATION: "itemInvoiceGentProgReset",
  //SKU
  POST_ADD_NEW_PRODUCT: "PostAddNewProduct",
  POST_ADD_NEW_CRATE: "Postaddnewcrate",
  GET_AVAILABLE_PRODUCTS: "GetAvailableProducts",
  GET_AVAILABLE_CRATE: "GetAvailableCrateDetails",
  LINK_PRODUCT_CRATE: "LinkProductCrate",
  DELETE_CRATE_PRODUCT: "DeleteCrateWithProduct",
  GET_IMAGE_FILES: "GetImageFilePath",

  //QR CODE
  GENERATE_QRCODE_CRATE: "GenerateQrCodeCrate",
  CRATE_QRBARCODE_LIST: "CrateQrBarCodeList",
  REGENERATE_CRATE_QRBAR_CODE: "RegenerateCrateQrBarCode",
  LINK_QR_BARCODE_CRATE: "LinkQrBarCodeCrates",
  RELINK_QR_BARCODE_CRATE: "ReLinkQrBarCodwCrate",
  QRLESSS_OUTSCAN: "QrLessProductInScan",

  //PINCODES
  GET_AVAILABLE_PINCODES: "GetAvailablePincodes",
  ADD_PINCODE_SERVICEAREA_SKU: "AddPincodeServiceArea",
  UPDATE_PINCODE_SKU: "UpdatePincodePrice",
  DELETE_LOCALITY: "DeleteLocalityFromPicode",
  DELETE_PRODUCT_LOCALITY: "DeleteProductFromLocality",

  //coustom product price
  COUSTOM_PRODUCT_PRICE: "CoustomProductPrice",
  DELETE_PRODUCT_DISCOUNT: "DeleteProductDiscount",
  COUSTOM_PRODUCT_PRICE_ADDRESS: "CoustomProductPerAddress",
  REMOVE_PRODUCT_PRICE_ADDRESS: "RemoveProductPriceAddress",
  CONSOLIDATE_PO_GET: "getOrderdataConsolidate",
  BUSINESS_USERS: "getPostpaidUsers",
  GET_BLOCKED_PAYMENT_METHODS: "getBlockedPaymentMethods",
  GET_BLOCKED_BOOKINGS: "getBlockedBookings",

  //sku wfs details
  WFS_DETAILS_SKU: "WfsDetailsSku",
  WFS_BATCH_DETAILS_SKU: "WfsBatchDetailsSku",
  CREATE_WFS_BATCH_SKU: "CreateWfsBatchDetails",
  EDIT_WFS_BATCH_SKU: "EditWfsBatchDetailsSku",
  CANCEL_WFS_BATCH_SKU: "CancelWfsBatchSku",
  GROUP_PRODUCT_WFS_SKU: "GroupProductWfsSku",
  CANCEL_GROUP_PRODUCT_WFS_SKU: "CancelGroupProductSku",
  LINK_QRLESS_PRODUCT: "LinkQrLessProdu",
  WFS_EMPTY_CAN_ORDERS: "WfsEmptyCanOrders",
  WFS_EMPTYCAN_DETAILS_SKU: "WfsEmptyCanOrderDetails",
  WFS_COMPLETE_ORDER_OTP: "WfsCompleteOrderOtp",
  EDIT_WFS_EMPTYCAN_SKU: "EditEmptyCanSku",
  WFS_EMPTY_CAN_ACCEPT_ORDER: "WfsEmptyCanAcceptOrdersSku",
  WFS_EMPTY_CAN_REJECT_ORDER: "WfsEmptyCanRejectOrdersSku",
  ASIGN_EMPTYCAN_AGENT: "AssignEmptyCanSku",
  EDIT_WAREHOUSE_EMPTYCAN: "EditWareHouseEmptyCan",
  //crate scan,
  CRATE_INSCAN_SKU: "CrateInscanSku",
  CRATE_OUTSCAN_SKU: "CrateOutscanSku",
  QRLESSS_INSCAN: "QrlessInscan",

  //Clear User Data
  CLEAR_DATA: "ClearData",
  CLEAR_UPDATE_USER_DATA: "ClearUpdateUserDetailsData",
  //Crate stck
  UPDATE_CRATE_USER_STOCK: "UpdateCrateStock",
  GET_PRODUCTS_ON_ADDRESS_ID: "getProductsBasedOnAddressId",
  VALIDATING_PRODUCT: "validatingProducts",
  BOOK_NOW_API: "bookNowApi",
  CONSMUER_COUPON_CODE: "consumerCouponCode",
  GET_NEW_SEARCH_ORDER_DETAILS: "GetNewSearchOrderDetails",
  GET_PDW_ORDER_DETAILS: "POST: Get the list pdw Order details!",
  GET_PDW_ORDER_SUMMARY_DETAILS: "GET: Get the orders and products summary!",
  VALIDATING_PRODUCT_SUBSCRIPTION: "validatingProductsSubscription",
  SUBSCRIPTION_BOOKING: "subscriptionBooking",
  GET_SUBSCRIPTION_DATA: "getAllSubscriptionData",
  UPDATE_SUBSCRIPTION: "updateSubscriptionData",
  EDIT_SUBSCRIPTION: "editSubscription",
  SET_LOADING: "SET_LOADING",
  SET_UPDATE_LOADING: "updateCartLoder",
  //Cancel Otp
  PUT_SUPPLIER_CURRENT_DELIVERIES_CANCEL_OTP: "PutSupplierCurrentDayCancelOtp",
  POST_FORCE_COMPLETE_SCANNING: "PostForceCompleteScanning",
  PUT_LINK_ORDER_TO_ORDER: "LinkProductsOrderToOrder",
  INFLUENCER_SETTLEMENTS_PAYMENT_SEND_MAIL:
    "PostSattlementPaymentAdviceSenEmail",
  CLEAR_DATA_REFRESH: "ClearDataRefersh",
  GET_NEW_PAYMENT_SUMMARY: "GetNewPaymentSummary",
  GET_NEW_SALES_SUMMARY: "GetNewSalsSummary",
  POST_FORCE_CONSUMER_APPS_UPDATE: "PostForceConsumerAppsUpdate",
  //In-Scan Block Settings
  PUT_BLOCK_INSCAN_SETTINGS: "PutInScanControlBlocking",
  EXTRA_EMPTY_RETURN: "extraEmptyReturn",
  VALIDATE_EXPIRED: "clearValidatedData",
  //Return Complete In-scan
  SUBMIT_IN_SCAN_RETURN: "SubmitReturnInScanAction",
  EMPLOYEE_PUNCHIN_DETAILS: "employeePunchInDetails",
  VEHICLE_CURRENT_TRIPS: "supplierVehicleTrips",
  //Return Can Edit
  PUT_EDIT_RETURN_CAN_ORDERS: "PutEditReturnCanOrder",
  //Complete Distributor Return Order
  COMPLETE_DISTRIBUTOR_RETURN_ORDER: "CompleteDistributorReturnOrder",
  //Complete Replacement Order
  PUT_COMPLETE_REPLACEMENT_ORDER: "CompleteReplacementOrder",
  //Edit Replacement Order
  EDIT_REPLACEMENT_ORDER: "EditReplacementOrder",
  //Edit ReturnOrder Distributor to WFS
  PUT_EDIT_RETURN_CAN_ORDERS_DISTRI_TO_WFS: "PutEditReturnCanOrderDistriToWfs",
  //Dashboard Sales and Product Summary
  GET_PRODUCT_SALES_SUMMARY: "GetSaleProductSummary",
  //Dashboard Deposits and users and wallet Summary
  GET_DEPOSITS_USERS_WALLET_SUMMARY: "GetDepositAndUsersWalletSummary",
  //Replacement Qr less product
  REPLACEMENT_INSCAN_QRLESS: "ReplacementQrlessInScan",
  //clear Batch Details
  CLEAR_DATA_REFRESH_BATCH: "ClearDataRefershBatch",
  //Recycle Orders
  GET_RECYCLE_ORDERS_LIST: "GetRequestedRecycleOrderList",
  GET_ALL_WAREHOUSE_DETAILS: "GetAllWareHouseDetails",
};
export default actionType;
